(function () {
    'use strict';

    angular.module('services.api').factory('PageAccessibilityRepo', PageAccessibilityRepo);

    PageAccessibilityRepo.$inject = ['ng2ApiService', 'ng2SessionService'];
    /* @ngInject */
    function PageAccessibilityRepo (ng2ApiService, ng2SessionService) {
        var service = {
            updateError,
            getAllErrors,
            updateCheck,
            getSummary,
            getAllChecks,
            getAllCheckErrors,
            markIssueAsFixed,
            markCheckAsFixed,
            updateCheckErrors,
        };

        return service;

        // /////////////

        function getSummary (pageId, params, config) {
            params = params || {};

            const domainId = ng2SessionService.getDomainId(params);
            params.group_id = ng2SessionService.getGroupId(params);

            return ng2ApiService.get(
                `domains/${domainId}/pages/${pageId}/accessibility/summary`,
                params,
                config,
            );
        }

        function getAllErrors (pageId, params, config) {
            params = params || {};

            const domainId = ng2SessionService.getDomainId(params);
            params.group_id = ng2SessionService.getGroupId(params);

            return ng2ApiService.get(
                `domains/${domainId}/pages/${pageId}/accessibility/errors`,
                params,
                config,
            );
        }

        function updateCheck (pageId, checkId, payload, params) {
            const domainId = ng2SessionService.getDomainId(params);

            return ng2ApiService.patch(
                `domains/${domainId}/pages/${pageId}/accessibility/checks/${checkId}`,
                payload,
            );
        }

        function getAllChecks (pageId, params, config) {
            params = params || {};

            const domainId = ng2SessionService.getDomainId(params);
            params.group_id = ng2SessionService.getGroupId(params);

            return ng2ApiService.get(
                `domains/${domainId}/pages/${pageId}/accessibility/checks/`,
                params,
                config,
            );
        }

        function getAllCheckErrors (pageId, checkId, params, config) {
            params = params || {};

            const domainId = ng2SessionService.getDomainId(params);
            params.group_id = ng2SessionService.getGroupId(params);

            return ng2ApiService.get(
                `domains/${domainId}/pages/${pageId}/accessibility/checks/${checkId}/errors`,
                params,
                config,
            );
        }

        function updateError (pageId, error, params) {
            const domainId = ng2SessionService.getDomainId(params);

            return ng2ApiService.patch(
                `domains/${domainId}/pages/${pageId}/accessibility/errors/${error.id}`,
                error,
            );
        }

        function markIssueAsFixed (pageId, error) {
            return ng2ApiService.delete(
                `domains/${ng2SessionService.domain.id}/pages/${pageId}/accessibility/errors/${error.id}`,
            );
        }

        function markCheckAsFixed (pageId, checkId) {
            return ng2ApiService.delete(
                `domains/${ng2SessionService.domain.id}/pages/${pageId}/accessibility/checks/${checkId}/errors`,
            );
        }

        function updateCheckErrors (pageId, payload) {
            return ng2ApiService.patch(
                `domains/${ng2SessionService.domain.id}/pages/${pageId}/accessibility/checks/${payload.id}/errors`,
                payload,
            );
        }
    }
})();
