import { MON_EVENTS } from '@monsido/core/constants/mon-events.constant';

(function () {
    'use strict';

    angular.module('core.services.domain').factory('coreDomainService', coreDomainService);

    coreDomainService.$inject = [
        'ng2SessionService',
        '$interval',
        'ng2DomainRepoService',
        'ng2ErrorHandler',
        '$localStorage',
        '$stateParams',
        'ng2MonEventsService',
        'gettextCatalog',
        'ng2MonModalService',
        'ng2DomainService',
        '$q',
        'AdminDomainsService',
        'ng2MeService',
        'ng2TotalDomainsService',
    ];

    /* @ngInject*/
    function coreDomainService (
        ng2SessionService,
        $interval,
        ng2DomainRepoService,
        ng2ErrorHandler,
        $localStorage,
        $stateParams,
        ng2MonEventsService,
        gettextCatalog,
        ng2MonModalService,
        ng2DomainService,
        $q,
        AdminDomainsService,
        ng2MeService,
        ng2TotalDomainsService,
    ) {
        let updateDomainFailedAttempts = 0;
        const service = {
            disposeDomains: function () {
                ng2TotalDomainsService.resetDomains();
                service.cancelDomainPromise();
            },
            setDomain: function (domain) {
                ng2SessionService.domain = domain;
                if (ng2SessionService.domain !== null && ng2SessionService.domainGroup !== null) {
                    // Check if domain and domainGroup is set
                    var domainGroups = ng2SessionService.domain.domain_groups.map(function (group) {
                        return group.id;
                    });
                    if (domainGroups.indexOf(ng2SessionService.domainGroup.id) === -1) {
                        // If domain group doesn't exist under the domain
                        service.removeDomainGroup();
                    }
                }
                ng2SessionService.domainObs.next(domain);
            },
            setDomainGroup: function (domainGroup) {
                ng2SessionService.domainGroup = domainGroup;
                $localStorage.domainGroup = domainGroup;
            },

            clearDomain: function () {
                ng2SessionService.domain = null;
            },

            removeDomainGroup: function () {
                delete $stateParams.domain_group;
                ng2SessionService.domainGroup = null;
                $localStorage.domainGroup = null;
            },
            setupDomainGroupAfterReload: function () {
                ng2SessionService.domainGroup = $localStorage.domainGroup || null;
            },

            getDomains: function () {
                var params = { page_size: 100, mini: true };
                var domainsArray = [];

                ng2TotalDomainsService.requestAllDomains({
                    params,
                    domainsArray,
                })
                    .finally(function () {
                        service.cancelDomainPromise();
                        service.getDomainsPromise = $interval(function () {
                            service.getDomains();
                        }, 900000);
                    });
            },
            disposeDomain: function () {
                if (!ng2SessionService.domainObs.isStopped) {
                    ng2SessionService.domainObs.unsubscribe();
                }
            },
            cancelDomainPromise: function () {
                if (service.getDomainsPromise !== null) {
                    $interval.cancel(service.getDomainsPromise);
                    service.getDomainsPromise = null;
                }
            },
            refreshDomain: function () {
                return updateDomain(true);
            },
            init: function () {
                ng2TotalDomainsService.resetDomains();
                cancelUpdateDomainPromise();
                service.updateDomainPromise = $interval(function () {
                    updateDomain();
                }, 90000); // 1 minute and 30 seconds
            },

            openDomainForm: function (domain, domainFormComponent) {
                var deferred = $q.defer();
                domain = domain || {};
                var params = {
                    body: domainFormComponent,
                    size: 'sm',
                    data: {
                        domain: domain,
                    },
                };

                const callback = function (callbackDomain) {
                    deferred.resolve(callbackDomain);
                };

                ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, { params, callback });
                return deferred.promise;
            },

            deleteDomain: function (domain) {
                var msg = gettextCatalog.getString(
                    'You are about to delete the domain <span class=\'text-link\'>{{domainTitle}}</span>' +
                        'please type <code>Delete</code> to confirm this action' +
                        '<div class=\'alert alert-danger py-1 px-1 mb-0 mt-3\'>' +
                        'By deleting this domain all domain groups related to this domain, will also be deleted</div>',
                    { domainTitle: domain.title },
                );

                var defer = $q.defer();
                ng2MonModalService.prompt(msg).then(function (result) {
                    if ('delete' !== result.toLowerCase().trim()) {
                        defer.reject();
                    } else {
                        ng2DomainService.destroy(domain.id).then(defer.resolve);
                    }
                }, angular.noop);
                return defer.promise;
            },

            toggleDomainPinned: function (domain) {
                return ng2MeService.updateDomain(domain.id, {
                    favorite: !domain.favorite,
                });
            },

            openScriptGuide: function (domain, bodyComponentName) {
                var params = {
                    body: bodyComponentName,
                    size: 'sm',
                    data: { domain: domain },
                };

                const callback = function () {
                    AdminDomainsService.updateDomainSettings(domain);
                };
                ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, { params, callback });
            },

            showCmsUrl: function (domain) {
                return ['sitecore', 'omniupdate', 'publicera', 'sitefinity', 'custom', 'cascade', 'aem', 'acos'].indexOf(domain.cms) !== -1;
            },

            showOptionalCmsUrl: function (domain) {
                return ['drupal', 'kentico', 'episerver'].indexOf(domain.cms) !== -1;
            },

            showSpecialKey: function (domain) {
                return ['special'].indexOf(domain.cms) !== -1;
            },

            showPrefixPath: function (domain) {
                return ['wordpress_multisite'].indexOf(domain.cms) !== -1;
            },

            showTemplatePath: function (domain) {
                return ['umbraco'].indexOf(domain.cms) !== -1;
            },

            getTemplatePlaceholder: function (domain) {
                if (['umbraco'].indexOf(domain.cms) !== -1) {
                    return '/umbraco/umbraco.aspx?app=content&rightAction=editContent&id=<%= id %>#content';
                }
            },

            getUrl: function (domain) {
                if (domain.cms === 'custom') {
                    return 'base_url';
                }
                return 'url';
            },

            setupScanLogin: function (loginOptions) {
                var login = null;
                if (loginOptions && loginOptions.type !== 'none') {
                    var optionsType = loginOptions.type;
                    login = {
                        type: optionsType,
                        verify: loginOptions.verify,
                    };
                    login[optionsType] = loginOptions[optionsType];
                }

                return login;
            },

            openScanDetails: function () {
                var params = {
                    body: 'scanDetailsDialog',
                    size: 'sm',
                    classes: 'fade-animation middle center',
                    data: {
                        domainObservable: ng2SessionService.domainObs,
                    },
                };
                ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, { params });
            },

            hasCrawlStatus: function () {
                return ng2SessionService.domain !== null && ng2SessionService.domain.crawl_status !== null;
            },

            getDomainCrawlStatus: function () {
                if (ng2SessionService.domain !== null && ng2SessionService.domain.crawl_status !== null) {
                    return ng2SessionService.domain.crawl_status.progress;
                } else {
                    return 0;
                }
            },
        };

        return service;

        // Private

        function updateDomain (force) {
            if (force || ng2SessionService.domain !== null) {
                var params = {};
                params.group_id = ng2SessionService.domainGroup ? ng2SessionService.domainGroup.id : null;
                if (!ng2SessionService.domain) {
                    return;
                }
                return ng2DomainRepoService.get(ng2SessionService.domain.id, params).then(
                    function (domain) {
                        updateDomainFailedAttempts = 0;
                        // Check if domain changed after fetching details
                        if (ng2SessionService.domain !== null && domain.id === ng2SessionService.domain.id) {
                            service.setDomain(domain);
                        }
                    },
                    function (error) {
                        updateDomainFailedAttempts++;
                        if (updateDomainFailedAttempts < 3) {
                            updateDomain();
                        } else {
                            ng2ErrorHandler.noopError(error, 'warning');
                        }
                    },
                );
            }
        }

        function cancelUpdateDomainPromise () {
            if (service.updateDomainPromise !== null) {
                $interval.cancel(service.updateDomainPromise);
                service.updateDomainPromise = null;
            }
        }
    }
})();
