import { Injectable } from '@angular/core';
import { ApiService } from '@client/app/services/api/api-service/api.service';
import { SessionService } from '@monsido/core/session/session.service';
import { RequestParamsType } from '@monsido/ng2/external/http/options';
import { AuditLogModel } from '@client/app/models/audit-log.model';
import { MonTableCollection } from '@client/ng2/models/table-collection.interface';

@Injectable({
    providedIn: 'root',
})
export class AuditRepoService {
    constructor (
        private apiService: ApiService,
        private sessionService: SessionService,
    ) {}

    async getAuditLogs (params: RequestParamsType = {}): Promise<MonTableCollection<AuditLogModel>> {
        params = this.sessionService.setGroupId(params);

        return this.apiService.get('audit_logs', params)
            .then((auditLogs: MonTableCollection<AuditLogModel>) => {
                for (let i = 0; i < auditLogs.length; i++) {
                    auditLogs[i] = new AuditLogModel(auditLogs[i]);
                }
                return auditLogs;
            });
    }
}
