import { map } from 'rxjs/operators';

(function () {
    'use strict';

    angular.module('services.api').factory('CrawlsRepo', CrawlsRepo);

    CrawlsRepo.$inject = ['ng2ApiService', 'ng2SessionService', 'DomainGroupsRepo', 'CrawlModel'];

    /* @ngInject */
    function CrawlsRepo (ng2ApiService, ng2SessionService, DomainGroupsRepo, CrawlModel) {
        var service = {
            getScanDetails,
            getAll,
            getAllObservable,
        };

        return service;

        // /////////////

        function getScanDetails (params, headers) {
            params = params || {};
            params.group_id = ng2SessionService.getGroupId(params);
            const domainId = ng2SessionService.getDomainId(params);

            if (domainId != null) {
                return ng2ApiService.get('domains/' + domainId + '/crawls/running', params, headers);
            } else {
                return Promise.reject(new Error('Missing Domain Id'));
            }
        }

        function getAll (params) {
            params = params || {};

            if (ng2SessionService.domainGroup !== null || angular.isNumber(params.group_id)) {
                return DomainGroupsRepo.getHistory(params);
            }

            return getHistoryForDomain(params);
        }

        function getAllObservable (params, canceler$) {
            params = params || {};

            if (ng2SessionService.domainGroup !== null || angular.isNumber(params.group_id)) {
                return DomainGroupsRepo.getHistoryObservable(params, canceler$);
            }

            return getHistoryForDomainObservable(params, canceler$);
        }

        // PRIVATE

        function getHistoryForDomain (params) {
            const domainId = ng2SessionService.getDomainId(params);
            const route = `domains/${domainId}/crawls`;

            return ng2ApiService
                .get(route, params)
                .then(toModels);
        }

        function getHistoryForDomainObservable (params, canceler$) {
            const domainId = ng2SessionService.getDomainId(params);
            const route = `domains/${domainId}/crawls`;

            return ng2ApiService
                .getObservable(route, params, {}, canceler$)
                .pipe(map(toModels));
        }

        function toModels (models) {
            for (let i = 0; i < models.length; i++) {
                models[i] = new CrawlModel(models[i]);
            }

            return models;
        }
    }
})();
