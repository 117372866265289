import { Injectable } from '@angular/core';
import { SessionService } from '@monsido/core/session/session.service';
import { Observable } from 'rxjs';
import { ApiClient } from '@monsido/modules/endpoints/api/api-client';

@Injectable({ providedIn: 'root' })
export class ExportService {

    constructor (private client: ApiClient, private ng2SessionService: SessionService) { }

    public create (data: unknown): Observable<unknown> {
        return this.client.postObservable('exports', data);
    }

    public get (exportId: number, params?): Observable<unknown> {
        params = params || {};
        params = this.ng2SessionService.setGroupId(params);

        return this.client.getObservable('exports/' + exportId, params);
    }

    public getExports (params): Observable<unknown> {
        params = params || {};
        params = this.ng2SessionService.setGroupId(params);

        return this.client.getObservable('exports', params);
    }
}
