import { Injectable } from '@angular/core';
import { ApiService } from '@client/app/services/api/api-service/api.service';
import { SessionService } from '@monsido/core/session/session.service';
import { PageModel, PageModelFactoryService } from '@client/app/models/page-model-factory.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Page, PageWithLink } from '@monsido/modules/page-details/models';
import { MonTableCollection } from '@client/ng2/models/table-collection.interface';
import { RequestParamsType } from '@monsido/ng2/external/http/options';
import { CancelerType } from '@monsido/http/http-client.service';
import { PageMetaData } from '@monsido/modules/inventory/interfaces/page-metadata.interface';
import { SeoIssue, SeoIssueContent } from '@client/app/modules/seo/interfaces/seo-issue.interface';
import { StatisticsSummary } from '@client/app/modules/statistics/interfaces/statistics-summary.interface';
import { StatisticsVisitor } from '@client/app/modules/statistics/interfaces/statistics-vistor.interface';

@Injectable({
    providedIn: 'root',
})
export class PageRepoService {
    constructor (
        private apiService: ApiService,
        private sessionService: SessionService,
        private pageModelFactoryService: PageModelFactoryService,
    ) {}

    async getStatSummary (pageId: number, params: RequestParamsType = {}): Promise<StatisticsSummary> {
        return this.apiService.get(
            `domains/${this.sessionService.getDomainId(params)}/pages/${pageId}/statistics/summary`,
            this.getParamsWithGroupId(params),
        );
    }
    async getStatVisitors (pageId: number, params: RequestParamsType = {}): Promise<StatisticsVisitor> {
        return this.apiService.get(
            `domains/${this.sessionService.getDomainId(params)}/pages/${pageId}/statistics/visitors`,
            this.getParamsWithGroupId(params),
        );
    }

    async updateSeoIssue (pageId: number, issueId: number, data: Partial<SeoIssue>): Promise<SeoIssue> {
        return this.apiService.patch(
            `domains/${this.sessionService.getDomainId({})}/pages/${pageId}/seo_issues/${issueId}`,
            data,
        );
    }

    async getSeoIssueContent (pageId: number, issueId: number): Promise<MonTableCollection<SeoIssueContent>> {
        return this.apiService.get(
            `domains/${this.sessionService.getDomainId({})}/pages/${pageId}/seo_issues/${issueId}/content`,
            this.getParamsWithGroupId(),
        );
    }

    async getSeoIssues (pageId: number, params: RequestParamsType = {}): Promise<MonTableCollection<SeoIssue>> {
        return this.apiService.get(
            `domains/${this.sessionService.getDomainId(params)}/pages/${pageId}/seo_issues`,
            this.getParamsWithGroupId(params),
        );
    }

    async get (pageId: number, params: RequestParamsType = {}): Promise<PageModel> {
        return this.apiService
            .get(
                `domains/${this.sessionService.getDomainId(params)}/pages/${pageId}`,
                this.getParamsWithGroupId(params),
            )
            .then((model: Page) => this.toModel(model));
    }

    async getMetadata (pageId: number, params: RequestParamsType = {}): Promise<MonTableCollection<PageMetaData>> {
        return this.apiService.get(
            `domains/${this.sessionService.getDomainId(params)}/pages/${pageId}/metadata`,
            this.getParamsWithGroupId(params),
        );
    }

    async getIncomingLinks (pageId: number, params: RequestParamsType = {}): Promise<MonTableCollection<PageWithLink>> {
        return this.apiService.get(
            `domains/${this.sessionService.getDomainId(params)}/pages/${pageId}/incoming`,
            this.getParamsWithGroupId(params),
        );
    }

    async getAll (params: RequestParamsType = {}): Promise<MonTableCollection<PageModel>> {
        const route = `domains/${this.sessionService.getDomainId(params)}/pages`;

        return this.apiService
            .get(route, this.getParamsWithGroupId(params))
            .then((models: MonTableCollection<Page>) => this.toModels(models));
    }

    getAllObservable (params: RequestParamsType = {}, canceler$?: CancelerType): Observable<MonTableCollection<PageModel>> {
        const route = `domains/${this.sessionService.getDomainId(params)}/pages`;

        return this.apiService
            .getObservable(route, this.getParamsWithGroupId(params), {}, canceler$)
            .pipe(map((models: MonTableCollection<Page>) => this.toModels(models)));
    }

    async update (pageId: number, data: Page): Promise<Page> {
        return this.apiService.patch(`domains/${this.sessionService.getDomainId({})}/pages/${pageId}`, data);
    }

    async rescan (pageId: number): Promise<void> {
        return this.apiService.post(`domains/${this.sessionService.getDomainId()}/pages/${pageId}/rescan`, {});
    }

    async getCmsUrl (pageId: number): Promise<{cms_url: string | null}> {
        return this.apiService.get(
            `domains/${this.sessionService.getDomainId()}/pages/${pageId}/cms_url`,
            this.getParamsWithGroupId(),
        );
    }

    async getScanStatus (pageId: number): Promise<{status: string, created_at: string}> {
        return this.apiService.get(
            `domains/${this.sessionService.getDomainId()}/pages/${pageId}/rescan/`,
            this.getParamsWithGroupId(),
        );
    }

    // Not under domains... & should not be. This async should ONLY be used by the spotlight application
    async allExistingPages (params: RequestParamsType = {}): Promise<MonTableCollection<PageModel>> {
        return this.apiService.get('pages', this.getParamsWithGroupId(params)).then((models: MonTableCollection<Page>) => this.toModels(models));
    }

    private getParamsWithGroupId (params: RequestParamsType = {}): RequestParamsType {
        params = this.sessionService.setGroupId(params);


        return params;
    }

    private toModels (models: MonTableCollection<Page>): MonTableCollection<PageModel> {
        for (let i = 0; i < models.length; i++) {
            models[i] = this.toModel(models[i]);
        }

        return models as unknown as MonTableCollection<PageModel>;
    }

    private toModel (model: Page): PageModel {
        return this.pageModelFactoryService.getModel(model);
    }
}
