import { Injectable } from '@angular/core';
import { ApiService } from '@client/app/services/api/api-service/api.service';
import { SessionService } from '@monsido/ng2/core/session/session.service';
import { RequestParamsType } from '@monsido/ng2/external/http/options';

type DataPrivacyInfoType = {
    deprecated: boolean,
    enabled: boolean,
    group: string,
    id: number,
    identifier: string,
    name: string,
    priority: string,
    regions: string[],
    regulations: string[]
}


type DataPrivacyDomainInfoType = DataPrivacyInfoType & {
    pages_count?: string
    info_type_domain_summary: {
        id: number,
        source_id: number,
        source_type: string,
        data_protection_violations_count: number,
        pages_with_active_violations_count: number,
        pages_with_ignored_violations_count: number,
        pages_with_fixed_violations_count: number,
        pages_without_active_violations_count: number
    }
}


@Injectable({
    providedIn: 'root',
})
export class DataPrivacyInfoTypeRepoService {

    constructor (private apiService: ApiService, private sessionService: SessionService) { }

    getAll (params: RequestParamsType): Promise<DataPrivacyInfoType> {
        params = params || {};
        return this.apiService.get<DataPrivacyInfoType>('info_types', params);
    }

    getAllForDomain (domainId: string, params: RequestParamsType): Promise<DataPrivacyDomainInfoType> {
        params = params || {};
        params = this.sessionService.setGroupId(params);

        return this.apiService.get('domains/' + domainId + '/data_protection/custom_ruleset', params);
    }
}
