(function () {
    'use strict';

    angular.module('app.components.documentDetails').component('documentDetails', {
        templateUrl: 'app/components/documentdetails/details/details.html',
        controller: DocumentDetailsController,
        controllerAs: 'vm',
        bindings: {
            document: '<',
            target: '<',
            subTarget: '<',
        },
    });

    DocumentDetailsController.$inject = [
        'LabelRepo',
        'pageService',
        'ng2SessionService',
        'gettextCatalog',
        '$filter',
        'MON_MODULES',
        'ng2SpellingToolRepoService',
        'ng2DemoHasAccessService',
        'ng2CoreLoginService',
    ];
    /* @ngInject */
    function DocumentDetailsController (
        LabelRepo,
        pageService,
        ng2SessionService,
        gettextCatalog,
        $filter,
        MON_MODULES,
        ng2SpellingToolRepoService,
        ng2DemoHasAccessService,
        ng2CoreLoginService,
    ) {
        var vm = this;
        vm.$onInit = activate;
        vm.refreshPage = refreshPage;
        vm.updatePage = updatePage;
        vm.getLabels = getLabels;
        vm.getTitle = getTitle;
        vm.setDocument = setDocument;
        vm.canSeePage = canSeePage;
        vm.goToSection = goToSection;
        vm.hasClarityAccess = hasClarityAccess;

        function activate () {
            vm.origPage = null;
            vm.page = null;
            vm.target = vm.target || null;
            vm.subTarget = vm.subTarget || null;
            vm.links = [];
            vm.languages = [];
            vm.isAdmin = ng2SessionService.hasRole('customer_admin');

            getLabels();
            getLanguages();
            getPage(true);
        }

        function getPage (runSetupActive) {
            pageService.get(vm.document).then(function (page) {
                vm.page = page;
                vm.origPage = angular.copy(page);
                setupLinks(runSetupActive);
            }, angular.noop);
        }

        function refreshPage (target) {
            vm.target = target;
            getPage(true);
        }

        function getLabels () {
            LabelRepo.getLabels().then(function (data) {
                vm.allLabels = data;
            }, angular.noop);
        }

        function updatePage (page) {
            var params = {
                language: page.language,
                labels: page.labels.map(function (label) {
                    return label.id;
                }),
            };

            pageService.update(vm.origPage, params);
        }

        function setDocument (document) {
            vm.document = document;
        }

        function getTitle () {
            if (!vm.page || !vm.page.title) {
                return gettextCatalog.getString('Unknown title');
            }

            return vm.page.title;
        }

        function canSeePage (link) {
            if (ng2DemoHasAccessService.isUsingDemoData(vm.origPage)) {
                return link.link === 'dashboard' || link.link === 'accessibility';
            }

            return true;
        }

        function goToSection (params) {
            vm.target = params.target;
            vm.subTarget = params.subTarget;
            setupActive();
        }

        function hasClarityAccess () {
            var clarity = Boolean(ng2SessionService.customer && ng2SessionService.customer.plan_traits.clarity_pdf_accessibility);
            return ng2DemoHasAccessService.canAccessAccessibilityPDF() || clarity;
        }

        // PROTECTED

        function setupActive () {
            var selected = null;
            if (vm.target) {
                for (var i = 0; i < vm.links.length; i++) {
                    if (vm.links[i].module === vm.target || 'page-details-section-' + vm.links[i].module === vm.target) {
                        selected = vm.links[i];
                        break;
                    }
                }
            }

            vm.active = selected || vm.links[0];
        }

        function getLanguages () {
            ng2SpellingToolRepoService.get().then(function (data) {
                vm.languages = data;
            }, angular.noop);
        }

        function setupLinks (runSetupActive) {
            ng2CoreLoginService.getUser().then(function (user) {
                user = user === null ? ng2SessionService.me : user;
                vm.links = [];
                var links = [
                    {
                        name: gettextCatalog.getString('Document Dashboard'),
                        hasFeature: true,
                        count: '',
                        icon: $filter('monIcons')(['MENUES', 'DASHBOARD']),
                        module: null,
                        link: 'dashboard',
                    },
                    {
                        name: gettextCatalog.getString('Policies'),
                        hasFeature: $filter('activeFeature')('global_policy'),
                        count: vm.page.policy_violation_count + vm.page.policy_required_count + vm.page.policy_search_count,
                        icon: $filter('monIcons')(['MENUES', 'POLICIES']),
                        module: MON_MODULES.policies,
                        link: 'policy',
                    },
                    {
                        name: gettextCatalog.getString('Quality Assurance'),
                        hasFeature: true,
                        count: vm.page.getQualityNotificationsCount(),
                        icon: $filter('monIcons')(['MENUES', 'QA']),
                        module: MON_MODULES.qa,
                        link: 'qa',
                    },
                    {
                        name: gettextCatalog.getString('Accessibility'),
                        hasFeature: true,
                        count: vm.page.accessibility_checks_with_errors_count,
                        show: ng2SessionService.domain.features.accessibility,
                        icon: $filter('monIcons')(['MENUES', 'ACCESSIBILITY']),
                        module: MON_MODULES.accessibility,
                        link: 'accessibility',
                    },
                    {
                        name: gettextCatalog.getString('Data Privacy'),
                        hasFeature: true,
                        show: ng2SessionService.domain.features.data_protection,
                        icon: $filter('monIcons')(['MENUES', 'DATA_PRIVACY']),
                        module: 'data-privacy',
                        link: 'data-privacy',
                    },
                    {
                        name: gettextCatalog.getString('Inventory'),
                        hasFeature: true,
                        icon: $filter('monIcons')(['MENUES', 'INVENTORY']),
                        module: MON_MODULES.inventory,
                        link: 'inventory',
                    },
                ];

                vm.links = links.filter(function (link) {
                    return link.hasFeature && linkHasModule(link, user);
                });

                if (runSetupActive) {
                    setupActive();
                }
            }, angular.noop);
        }

        function linkHasModule (link, user) {
            return link.module === null || $filter('hasAccess')(user, link.module);
        }
    }
})();
