import { Injectable } from '@angular/core';
import { ApiService } from '@client/app/services/api/api-service/api.service';
import { SessionService } from '@monsido/core/session/session.service';
import { RequestParamsType } from '@monsido/ng2/external/http/options';
import { AccessibilitySummaryEntityType } from '@client/app/modules/accessibility/interfaces/accessibility-summary.interface';
import { MonTableCollection } from '@client/ng2/models/table-collection.interface';
import { Page } from '@monsido/modules/page-details/models';

@Injectable({
    providedIn: 'root',
})
export class AccessibilityRepoService {
    constructor (
        private apiService: ApiService,
        private sessionService: SessionService,
    ) {}

    async getSummary (): Promise<AccessibilitySummaryEntityType> {
        let params: RequestParamsType = {};
        params = this.sessionService.setGroupId({});

        return this.apiService.get(`domains/${this.sessionService.getDomainId({})}/accessibility/summary`, params);
    }

    async getAllPages (params: RequestParamsType = {}): Promise<MonTableCollection<Page>> {
        params = this.sessionService.setGroupId(params);

        return this.apiService.get(`domains/${this.sessionService.getDomainId({})}/accessibility/pages`, params);
    }
    async getPagesWithIgnoredChecks (params: RequestParamsType = {}): Promise<MonTableCollection<Page>> {
        params = this.sessionService.setGroupId(params);

        return this.apiService.get(`domains/${this.sessionService.getDomainId({})}/accessibility/ignored-checks/pages`, params);
    }

}
