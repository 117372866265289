import { Injectable } from '@angular/core';
import { ApiService } from '../api-service/api.service';
import { SessionService } from '@monsido/ng2/core/session/session.service';
import { DowntimeType, UptimeReportType, UptimeType } from './uptime.type';
import { RequestHeadersType, RequestParamsType } from '@monsido/ng2/external/http/options';

@Injectable({
    providedIn: 'root',
})
export class UptimeRepoService {
    constructor (
        private apiService: ApiService,
        private sessionService: SessionService,
    ) { }

    getAll (params?: RequestParamsType, domainId?: number): Promise<UptimeType[]> {
        domainId = domainId || this.sessionService.getDomainId(params);

        if (!domainId) {
            // eslint-disable-next-line no-console
            console.warn('No domain id was provided!');
            return Promise.resolve([]);
        }

        params = this.setGroupId(params);

        return this.apiService.get('domains/' + domainId + '/uptimes', params);
    }

    getReport (id: number, params?: RequestParamsType, headers?: RequestHeadersType): Promise<UptimeReportType> {
        params = this.setGroupId(params);

        return this.apiService.get(
            'domains/' + this.sessionService.getDomainId(params) + '/uptimes/' + id + '/report',
            params,
            headers,
        );
    }

    getDowntimes (id: number, params?: RequestParamsType): Promise<DowntimeType[]> {
        params = this.setGroupId(params);

        return this.apiService.get(
            'domains/' + this.sessionService.getDomainId(params) + '/uptimes/' + id + '/downtimes',
            params,
        );
    }

    get (id: number, headers?: RequestHeadersType): Promise<UptimeType[]> {
        const params = this.setGroupId();

        return this.apiService.get(
            'domains/' + this.sessionService.getDomainId() + '/uptimes/' + id,
            params,
            headers,
        );
    }

    destroy (id: number): Promise<void> {
        return this.apiService.delete('domains/' + this.sessionService.getDomainId() + '/uptimes/' + id);
    }

    create (data: unknown): Promise<void> {
        return this.apiService.post(
            'domains/' + this.sessionService.getDomainId() + '/uptimes',
            data,
        );
    }

    update (data: Partial<UptimeReportType> & { id: number }): Promise<void> {
        return this.apiService.patch(
            'domains/' + this.sessionService.getDomainId() + '/uptimes/' + data.id,
            data,
        );
    }

    getAllScheduledDowntimes (uptimeId: number, params?: RequestParamsType): Promise<DowntimeType[]> {
        params = this.setGroupId(params);

        return this.apiService.get(
            '/domains/' + this.sessionService.getDomainId(params) + '/uptimes/' + uptimeId + '/scheduled_downtimes',
            params,
        );
    }

    createScheduledDowntime (uptimeId: number, data: unknown): Promise<void> {
        return this.apiService.post(
            '/domains/' + this.sessionService.getDomainId() + '/uptimes/' + uptimeId + '/scheduled_downtimes',
            data,
        );
    }

    updateScheduledDowntime (uptimeId: number, id: number, data: Partial<DowntimeType>): Promise<void> {
        return this.apiService.patch(
            '/domains/' + this.sessionService.getDomainId() + '/uptimes/' + uptimeId + '/scheduled_downtimes/' + id,
            data,
        );
    }

    destroyScheduledDowntime (uptimeId: number, id: number): Promise<void> {
        return this.apiService.delete(
            '/domains/' + this.sessionService.getDomainId() + '/uptimes/' + uptimeId + '/scheduled_downtimes/' + id,
        );
    }

    private setGroupId (params?: RequestParamsType): RequestParamsType {
        params = params || {};
        params = this.sessionService.setGroupId(params);

        return { ...params };
    }
}
