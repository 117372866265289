(function () {
    'use strict';

    angular.module('app.forms').component('formDomainScriptGuideLegacyPageAssist', {
        templateUrl: 'app/forms/domain-script-guide/legacy/page-assist/page-assist.html',
        controller: DomainScriptGuideFeaturesPageAssistController,
        controllerAs: 'vm',
        bindings: {
            prefixName: '@?',
            domain: '<',
            pageAssistModel: '<',
        },
    });

    DomainScriptGuideFeaturesPageAssistController.$inject = [
        '$timeout',
        'ng2FormDomainScriptGuidePageAssistService',
        'FormDomainScriptGuideService',
    ];
    /* @ngInject */
    function DomainScriptGuideFeaturesPageAssistController (
        $timeout,
        ng2FormDomainScriptGuidePageAssistService,
        FormDomainScriptGuideService,
    ) {
        var vm = this;
        vm.$onInit = activate;
        vm.updateDomainSettings = updateDomainSettings;
        vm.canUseFeature = canUseFeature;
        vm.onEnabledChange = onEnabledChange;

        function activate () {
            vm.settingEnabled = vm.pageAssistModel.getIsEnabled();
        }

        function onEnabledChange () {
            $timeout(function () {
                vm.pageAssistModel.settings.enabled = vm.settingEnabled;
                vm.pageAssistModel.legacySettings.enabled = vm.settingEnabled;
                updateDomainSettings();
            });
        }

        function updateDomainSettings () {
            $timeout(function () {
                FormDomainScriptGuideService.setPageAssistEdit(vm.pageAssistModel);
            });
        }

        function canUseFeature () {
            return ng2FormDomainScriptGuidePageAssistService.getAccessToFeature(vm.domain);
        }
    }
})();
