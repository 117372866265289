import { Injectable } from '@angular/core';
import { ApiService } from '@client/app/services/api/api-service/api.service';
import { SessionService } from '@monsido/core/session/session.service';
import { RequestParamsType } from '@monsido/ng2/external/http/options';
import { MonTableCollection } from '@client/ng2/models/table-collection.interface';
import { AccessibilityCheckEntityType } from '@client/app/modules/accessibility/interfaces/accessibility-check.interface';
import { AccessibilityGroup } from '@client/app/modules/accessibility/interfaces/accessibility-group.interface';

@Injectable({
    providedIn: 'root',
})
export class AccessibilityGuidelinesRepoService {
    constructor (
        private apiService: ApiService,
        private sessionService: SessionService,
    ) {}

    async getAll (): Promise<MonTableCollection<AccessibilityGroup>> {
        let params: RequestParamsType = {};
        const domainId = this.sessionService.getDomainId();
        params = this.sessionService.setGroupId(params);
        return this.apiService.get(`domains/${domainId}/accessibility/groups`, params);
    }

    async getAllChecksForSubGroup (
        accGroupId: number,
        subGroupId: number,
        params: RequestParamsType,
    ): Promise<MonTableCollection<AccessibilityCheckEntityType>> {
        params = this.sessionService.setGroupId(params);

        return this.apiService.get(
            `domains/${this.sessionService.getDomainId()}/accessibility/groups/${accGroupId}/subgroups/${subGroupId}/checks`,
            params,
        );
    }
}
