(function () {
    'use strict';

    angular.module('pages.inventory.dashboard').component('inventoryDashboard', {
        templateUrl: 'app/pages/inventory/dashboard/dashboard.html',
        controller: InventoryOverviewController,
        controllerAs: 'vm',
    });

    InventoryOverviewController.$inject = [
        'gettextCatalog',
        'CrawlsRepo',
        '$filter',
        'LinksRepo',
        'ng2PageRepoService',
        'moment',
        '$q',
        '$deepmerge',
        'ng2SessionService',
    ];

    function InventoryOverviewController (gettextCatalog, CrawlsRepo, $filter, LinksRepo, ng2PageRepoService, moment, $q, $deepmerge, ng2SessionService) {
        var vm = this;
        vm.$onInit = activate;
        vm.$onDestroy = onDestroy;

        function activate () {
            vm.allowActions = true;
            vm.loading = true;
            $q.all([setupChart(), setupCollections()]).finally(function () {
                vm.loading = false;
            });
        }

        function onDestroy () {
            vm.allowActions = false;
        }

        function setupChart () {
            vm.series = [
                gettextCatalog.getString('Crawled pages'),
                gettextCatalog.getString('Images'),
                gettextCatalog.getString('Documents'),
            ];
            vm.options = {
                scales: {
                    xAxes: [
                        {
                            gridLines: {
                                display: false,
                            },
                        },
                    ],
                    yAxes: [
                        {
                            id: vm.series[0],
                            type: 'linear',
                            display: true,
                            position: 'left',
                            ticks: {
                                beginAtZero: true,
                                suggestedMax: 4,
                                maxTicksLimit: 5,
                                callback: function (value) {
                                    return $filter('numeraljs')(value);
                                },
                                min: 0,
                            },
                        },
                        {
                            id: vm.series[1],
                            type: 'linear',
                            display: false,
                            position: 'left',
                            ticks: { beginAtZero: true, suggestedMax: 4, maxTicksLimit: 5 },
                        },
                        {
                            id: vm.series[2],
                            type: 'linear',
                            display: false,
                            position: 'left',
                            ticks: { beginAtZero: true, suggestedMax: 4, maxTicksLimit: 5 },
                        },
                    ],
                },
                legend: {
                    display: true,
                    position: 'bottom',
                    labels: { boxWidth: 15, padding: 15, fontSize: 11 },
                },
                tooltips: {
                    enabled: true,
                    mode: 'x-axis',
                    bodySpacing: 5,
                    callbacks: {
                        label: function (tooltipItem, data) {
                            return $filter('numeraljs')(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]);
                        },
                    },
                },
                hover: {
                    mode: 'x-axis',
                },
            };
            vm.labels = [];
            vm.data = [];

            var params = {
                page_size: 5,
            };

            return CrawlsRepo.getAll(params).then(function (data) {
                if (!vm.allowActions) {
                    return;
                }
                var plainData = data.reverse();
                var arr1 = [];
                var arr2 = [];
                var arr3 = [];
                for (var k = 0; k < plainData.length; k++) {
                    vm.labels.push(moment(data[k].date).format('MMM DD'));
                    arr1.push(plainData[k].page_count);
                    arr2.push(plainData[k].images_count);
                    arr3.push(plainData[k].documents_count);
                }
                vm.data.push(arr1);
                vm.data.push(arr2);
                vm.data.push(arr3);
            }, angular.noop);
        }

        function setupCollections () {
            var chain = $q.when();
            var documentScan = ng2SessionService.domain && ng2SessionService.domain.scan.scan_documents;
            vm.contentList = [
                {
                    link: 'base.customer.domain.inventory.content.pages',
                    faIcon: $filter('monIcons')(['SIDEMENUES', 'INVENTORY', 'CONTENT_HTML']),
                    classColor: 'text-link',
                    title: gettextCatalog.getString('HTML Pages'),
                    message: gettextCatalog.getString('No HTML Pages found'),
                    type: 'html',
                },
                {
                    link: 'base.customer.domain.inventory.content.documents',
                    faIcon: $filter('monIcons')(['SIDEMENUES', 'INVENTORY', 'CONTENT_DOCUMENTS']),
                    classColor: 'text-link',
                    title: gettextCatalog.getString('Documents'),
                    message: gettextCatalog.getString('No Documents found'),
                    type: documentScan ? ['pdf', 'document'] : 'documents',
                    status: 'active',
                },
            ];
            vm.technicalList = [
                {
                    link: 'base.customer.domain.inventory.content.css',
                    faIcon: $filter('monIcons')(['SIDEMENUES', 'INVENTORY', 'TECHNICAL_CSS']),
                    classColor: 'text-link',
                    title: gettextCatalog.getString('CSS'),
                    message: gettextCatalog.getString('No CSS'),
                    type: 'css',
                },
                {
                    link: 'base.customer.domain.inventory.content.js',
                    faIcon: $filter('monIcons')(['SIDEMENUES', 'INVENTORY', 'TECHNICAL_JS']),
                    classColor: 'text-link',
                    title: gettextCatalog.getString('Javascript'),
                    message: gettextCatalog.getString('No javascript'),
                    type: 'js',
                },
                {
                    link: 'base.customer.domain.inventory.content.frame',
                    faIcon: $filter('monIcons')(['INVENTORY', 'DASHBOARD', 'CODE']),
                    classColor: 'text-link',
                    title: gettextCatalog.getString('Frames'),
                    message: gettextCatalog.getString('No Frames'),
                    type: 'frame',
                },
                {
                    link: 'base.customer.domain.inventory.content.iframe',
                    faIcon: $filter('monIcons')(['INVENTORY', 'DASHBOARD', 'CODE']),
                    classColor: 'text-link',
                    title: gettextCatalog.getString('IFrames'),
                    message: gettextCatalog.getString('No IFrames'),
                    type: 'iframe',
                },
            ];
            vm.linksList = [
                {
                    link: 'base.customer.domain.inventory.content.images',
                    faIcon: $filter('monIcons')(['SIDEMENUES', 'INVENTORY', 'CONTENT_IMAGES']),
                    classColor: 'text-link',
                    title: gettextCatalog.getString('Image Links'),
                    message: gettextCatalog.getString('No Image Links found'),
                    type: 'image',
                },
                {
                    link: 'base.customer.domain.inventory.content.links',
                    faIcon: $filter('monIcons')(['SIDEMENUES', 'INVENTORY', 'CONTENT_LINKS']),
                    classColor: 'text-link',
                    title: gettextCatalog.getString('Content Links'),
                    message: gettextCatalog.getString('No Content links'),
                    type: ['link', 'spreadsheet', 'pdf', 'archive', 'document', 'file'],
                },
                {
                    link: 'base.customer.domain.inventory.content.links',
                    linkParams: { 'doc-target': 'spreadsheet' },
                    faIcon: $filter('monIcons')(['INVENTORY', 'DASHBOARD', 'SPREADSHEET']),
                    classColor: 'text-link',
                    title: gettextCatalog.getString('Spreadsheet links'),
                    message: gettextCatalog.getString('No Spreadsheet links found'),
                    type: 'spreadsheet',
                },
                {
                    link: 'base.customer.domain.inventory.content.links',
                    linkParams: { 'doc-target': 'document' },
                    faIcon: $filter('monIcons')(['INVENTORY', 'DASHBOARD', 'WORD']),
                    classColor: 'text-link',
                    title: gettextCatalog.getString('Word links'),
                    message: gettextCatalog.getString('No Word links found'),
                    type: 'document',
                },
                {
                    link: 'base.customer.domain.inventory.content.links',
                    linkParams: { 'doc-target': 'slide' },
                    faIcon: $filter('monIcons')(['TABS', 'PAGES', 'SLIDE']),
                    classColor: 'text-link',
                    title: gettextCatalog.getString('Slide links'),
                    message: gettextCatalog.getString('No slide links found'),
                    type: 'slide',
                },
            ];

            vm.contentList.forEach(function (content) {
                chain = chain.then(function () {
                    if (!vm.allowActions) {
                        return;
                    }
                    if (!documentScan && content.link === 'base.customer.domain.inventory.content.documents') {
                        return getLinkCount(content);
                    } else {
                        return getPageCount(content);
                    }
                }, angular.noop);
            });
            vm.technicalList.forEach(function (technical) {
                chain = chain.then(function () {
                    if (!vm.allowActions) {
                        return;
                    }
                    getLinkCount(technical);
                }, angular.noop);
            });
            vm.linksList.forEach(function (link) {
                chain = chain.then(function () {
                    if (!vm.allowActions) {
                        return;
                    }
                    return getLinkCount(link);
                }, angular.noop);
            });

            return chain;
        }

        function getPageCount (item) {
            var params = {
                page_size: 1,
            };

            if (Array.isArray(item.type)) {
                params['types[]'] = item.type;
            } else {
                params.type = item.type;
            }

            item.count = 0;
            return ng2PageRepoService.getAll(params).then(function (pages) {
                item.count = parseInt(pages.total, 10);
            }, angular.noop);
        }

        function getLinkCount (item) {
            var params = {
                page_size: 1,
                page_fixed: false,
                status: item.status || null,
            };

            item.count = 0;
            if (!Array.isArray(item.type)) {
                params.type = item.type;
                return getLinks(params).then(function (links) {
                    item.count = parseInt(links.total, 10);
                }, angular.noop);
            } else {
                var chain = $q.when();
                item.type.forEach(function (type) {
                    chain = chain.then(function () {
                        if (!vm.allowActions) {
                            return;
                        }
                        return getLinks($deepmerge(params, { type: type })).then(function (links) {
                            item.count = item.count + parseInt(links.total, 10);
                        }, angular.noop);
                    }, angular.noop);
                });
                return chain;
            }
        }

        function getLinks (params) {
            return LinksRepo.getAll(params);
        }
    }
})();
