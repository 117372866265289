import { MON_EVENTS } from '@monsido/core/constants/mon-events.constant';

(function () {
    'use strict';

    angular.module('modules.accessibility').component('accessibilityFastTrack', {
        templateUrl: 'app/modules/accessibility/dialogs/fast-track/fast-track.html',
        controller: AccessibilityFastTrackController,
        controllerAs: 'vm',
    });

    AccessibilityFastTrackController.$inject = [
        'AccessibilityFastTrackRepo',
        '$controller',
        'ng2MonEventsService',
        'AccessibilityFastTrackService',
        '$deepmerge',
        'accessibilityAutofixService',
        'ng2SessionService',
        'ng2ActiveFeatureService',
        'PageAccessibilityRepo',
        'ng2ExtensionService',
        '$window',
        'gettextCatalog',
        'ng2MonModalService',
        'accessibilityService',
        'FastTrackService',
    ];

    function AccessibilityFastTrackController (
        AccessibilityFastTrackRepo,
        $controller,
        ng2MonEventsService,
        AccessibilityFastTrackService,
        $deepmerge,
        accessibilityAutofixService,
        ng2SessionService,
        ng2ActiveFeatureService,
        PageAccessibilityRepo,
        ng2ExtensionService,
        $window,
        gettextCatalog,
        ng2MonModalService,
        accessibilityService,
        FastTrackService,
    ) {
        var vm = this;
        vm.$onInit = activate;
        vm.$onDestroy = onDestroy;
        vm.getPage = getPage;
        vm.hasSnippet = hasSnippet;
        vm.setActiveSnippet = setActiveSnippet;
        vm.openExample = openExample;
        vm.markAsReviewed = markAsReviewed;
        vm.closeCaptionSuccess = AccessibilityFastTrackService.closeCaptionSuccess;
        vm.isClosedCaption = AccessibilityFastTrackService.isClosedCaption;
        vm.updateSnippet = updateSnippet;
        vm.openPages = openPages;
        vm.markCheckAsFixed = markCheckAsFixed;

        function activate () {
            ng2MonEventsService.run('openFasttrack');
            angular.extend(vm, $controller('BaseApiController', { vm: vm }));
            vm.search = '';
            vm.snippets = [];
            vm.checks = [];
            vm.activeSnippet = null;
            vm.loadingChecks = false;
            vm.affectedPagesCount = 0;
            vm.chartData = [0, 1];
            vm.showAutofix =
                ng2ActiveFeatureService.isFeatureActive('page_fix_accessibility_check') ||
                ng2ActiveFeatureService.isFeatureActive('page_fix_accessibility_source_code');
            vm.allowIgnoreAction = !ng2SessionService.isDomainGroup();
            ng2ExtensionService.isInstalled().then(function (res) {
                vm.extensionInstalled = res;
            }, angular.noop);
            AccessibilityFastTrackService.setupSession();
            getPage();
        }

        function onDestroy () {
            AccessibilityFastTrackService.clear();
        }

        function hasSnippet () {
            return vm.activeSnippet != null;
        }

        function getPage () {
            var params = {
                page: vm.page,
                page_size: vm.pageSize,
                status: 'all',
                search: vm.search || null,
            };

            vm.progress = AccessibilityFastTrackRepo.getAll(params).then(function (snippets) {
                if (snippets.length > 0 && !hasSnippet()) {
                    setActiveSnippet(snippets[0]);
                }
                vm.snippets = snippets;
            }, angular.noop);
        }

        function setActiveSnippet (snippet) {
            vm.activeSnippet = snippet;
            getChecks();
        }

        function openExample (check) {
            var params = {
                page: 1,
                page_size: 1,
                search: null,
                sort_by: 'hits',
                sort_dir: 'desc',
            };

            AccessibilityFastTrackRepo.getAllPages(vm.activeSnippet.id, check.id, params).then(function (data) {
                if (!data || data.length === 0) {
                    return;
                }
                var page = data[0];
                return PageAccessibilityRepo.getAllCheckErrors(page.id, check.id, params).then(function (errors) {
                    if (!errors || errors.length === 0) {
                        return;
                    }
                    if (!vm.extensionInstalled) {
                        const params = {
                            body: 'extensionGuideDialog',
                            size: 'sm',
                            classes: 'fade-animation middle center',
                        };
                        ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, { params });
                        return;
                    }
                    var deeplinkParams = {
                        page: page,
                        type: 'acc',
                        data: { check: check, error: errors[0] },
                    };
                    if (ng2ExtensionService.getDeeplinkMethod() === 'beacon') {
                        ng2ExtensionService.sendDeeplinkMessage('deeplink/openPage', {
                            url: page.url,
                            params: ng2ExtensionService.getExtensionDeeplinkParams(deeplinkParams),
                        });
                    } else {
                        $window.open(ng2ExtensionService.getExtensionDeepLink(deeplinkParams), '_blank');
                    }
                });
            }, angular.noop);
        }

        function markAsReviewed (check, type) {
            var snippet = vm.activeSnippet;
            var translation = gettextCatalog.getString('<strong>{{checkName}}</strong><br><br>Add reason (Optional)', {
                checkName: check.name,
            });

            ng2MonModalService.prompt(translation).then(function (result) {
                var mergedCheck = $deepmerge(check, { comment: result });
                accessibilityService.fastTrackUpdateCheck(snippet.id, mergedCheck, type).then(function () {
                    AccessibilityFastTrackService.addExcludedCheck(snippet, check);
                    if (vm.activeSnippet.id === snippet.id) {
                        updateSnippet(vm.activeSnippet);
                    }
                }, angular.noop);
            }, angular.noop);
        }

        function markCheckAsFixed (check) {
            FastTrackService.markCheckAsFixed(ng2SessionService.domain.id, vm.activeSnippet.id, check.id).then(function () {
                updateSnippet(vm.activeSnippet);
            }, angular.noop);
        }

        function updateSnippet (snippet) {
            vm.loadingSnippet = true;
            AccessibilityFastTrackRepo.get(snippet.id)
                .then(function (newSnippet) {
                    if (vm.activeSnippet.id === snippet.id) {
                        vm.activeSnippet.caused_issues = vm.checks.length;
                        vm.activeSnippet.effect_on_compliance = newSnippet.effect_on_compliance;
                        getChecks();
                    }
                }, angular.noop)
                .finally(function () {
                    vm.loadingSnippet = false;
                });
        }

        function openPages (check) {
            var params = {
                body: 'fastTrackDialogsPages',
                size: 'lg',
                data: {
                    accessibilitySourceCodeId: vm.activeSnippet.id,
                    check: check,
                },
            };

            var callback = function () {
                updateSnippet(vm.activeSnippet);
            };

            ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, { params, callback });
        }

        // PROTECTED

        function getChecks () {
            if (vm.activeSnippet === null) {
                return;
            }
            var snippet = vm.activeSnippet;
            vm.loadingChecks = true;
            AccessibilityFastTrackRepo.getAllChecks(snippet.id)
                .then(
                    function (checks) {
                        var includedChecks = AccessibilityFastTrackService.getNonExcludedChecks(snippet, checks);
                        return accessibilityAutofixService.parseChecks(includedChecks, snippet).then(function (parsedChecks) {
                            vm.checks = parsedChecks;
                            updateDropdowns();
                            snippet.caused_issues = vm.checks.length;
                        });
                    },
                    function () {
                        vm.checks = [];
                    },
                )
                .finally(function () {
                    vm.loadingChecks = false;
                    vm.affectedPagesCount = AccessibilityFastTrackService.getAffectedPagesCount(vm.checks);
                    vm.chartData = [vm.affectedPagesCount, ng2SessionService.domain.crawled_pages - vm.affectedPagesCount];
                });
        }

        function updateDropdowns () {
            for (const check of vm.checks) {
                const dropdown = [
                    {
                        label: gettextCatalog.getString('Review'),
                        leftIcon: 'faEye',
                        action: ()=>markAsReviewed(check, 'Review'),
                        eventParams: { action: 'fasttrack-review' },
                        shouldShow: check.result_type === 'review',
                    },
                    {
                        label: gettextCatalog.getString('Ignore'),
                        leftIcon: 'faEyeSlash',
                        action: ()=>markAsReviewed(check, 'Ignore'),
                        eventParams: { action: 'fasttrack-ignore' },
                        shouldShow: vm.allowIgnoreAction && (check.result_type === 'error' || check.result_type === 'warning'),
                    },
                    {
                        label: gettextCatalog.getString('Mark check as fixed'),
                        leftIcon: 'faCheck',
                        action: ()=>markCheckAsFixed(check),
                        eventParams: { action: 'fasttrack-mark-fixed' },
                        shouldShow: check.result_type === 'error' || check.result_type === 'warning',
                    },
                    {
                        label: gettextCatalog.getString('Open example'),
                        leftIcon: 'faSearch',
                        action: ()=>openExample(check),
                    },
                ];

                check.dropdown = dropdown.filter((option)=>option.shouldShow !== false).map(item => {
                    return {
                        label: item.label,
                        leftIcon: item.leftIcon,
                        action: ()=>{
                            if (item.eventParams) {
                                ng2MonEventsService.run('actionTrigger', item.eventParams);
                            }

                            item.action();
                        },
                    };
                });
            }
        }
    }
})();
