import { getRxSubject } from '@monsido/ng2/shared/utils';
import { Domain } from '@monsido/ng2/modules/models/api/domain';
import { CRAWL_STATUS } from 'app/core/constants/helpers/crawl-status';
import { cloneDeep } from 'lodash';

(function () {
    'use strict';

    angular.module('pages.admin.domain').component('adminDomains', {
        templateUrl: 'app/pages/admin/domains/domains.html',
        controller: DomainsController,
        controllerAs: 'vm',
    });

    DomainsController.$inject = [
        'accountService',
        'ng2TotalDomainsService',
        'ng2ParamService',
        'rx',
        'ng2ActiveFeatureService',
        '$filter',
        'Lodash',
        'MON_EVENTS',
        'AdminDomainsService',
        'FORM_CONSTANT',
        'domainGroupService',
        'ng2ErrorHandler',
        'ng2DomainRepoService',
        'coreDomainService',
        'ng2CoreLoginService',
        'LabelRepo',
        'hotkeys',
        'ng2MonEventsService',
        'ng2DomainGroupUpdateInfoService',
        'ng2DomainService',
        'MON_MODULES',
        'ng2SessionService',
        '$state',
        'gettextCatalog',
        '$scope',
        'activeFeatures',
        '$controller',
        'ng2MonModalService',
    ];
    /* @ngInject */
    function DomainsController (
        accountService,
        ng2TotalDomainsService,
        ng2ParamService,
        rx,
        ng2ActiveFeatureService,
        $filter,
        Lodash,
        MON_EVENTS,
        AdminDomainsService,
        FORM_CONSTANT,
        domainGroupService,
        ng2ErrorHandler,
        ng2DomainRepoService,
        coreDomainService,
        ng2CoreLoginService,
        LabelRepo,
        hotkeys,
        ng2MonEventsService,
        ng2DomainGroupUpdateInfoService,
        ng2DomainService,
        MON_MODULES,
        ng2SessionService,
        $state,
        gettextCatalog,
        $scope,
        activeFeatures,
        $controller,
        ng2MonModalService,
    ) {
        const vm = this;
        const updateDropdowns = Lodash.debounce(() => {
            // extra check to avoid unit test failures on Jenkins
            if (!vm.destroyed) {
                for (let i = 0; i < vm.collection.length; i++) {
                    updateDropdown(i);
                }
            }
        }, 300);
        const onDestroy$ = getRxSubject();
        vm.updatingGroups = {}; // Record<domainId:number, groupIds: Set<number>>
        vm.$onInit = activate;
        vm.$onDestroy = onDestroy;
        vm.getPage = getPage;
        vm.onSearchTable = onSearchTable;
        vm.onPageChangeTable = onPageChangeTable;
        vm.onPageSizeChangeTable = onPageSizeChangeTable;
        vm.createDomain = createDomain;
        vm.editDomain = editDomain;
        vm.onConfirmDelete = onConfirmDelete;
        vm.startOnDemandScan = startOnDemandScan;
        vm.stopCurrentCrawl = stopCurrentCrawl;
        vm.openScriptGuide = openScriptGuide;
        vm.editDomainGroup = editDomainGroup;
        vm.onConfirmDeleteGroup = onConfirmDeleteGroup;
        vm.editExcludedIps = editExcludedIps;
        vm.goToDomain = goToDomain;
        vm.updateDomainLabels = updateDomainLabels;
        vm.getLabels = getLabels;
        vm.hasConstraintsOrExcludes = hasConstraintsOrExcludes;
        vm.onFiltersChanges = onFiltersChanges;
        vm.cloneDomain = cloneDomain;
        vm.updateDomainGroup = updateDomainGroup;
        vm.openDataPrivacy = openDataPrivacy;
        vm.search$ = null;
        vm.updateCustomer = updateCustomer;

        function activate () {
            angular.extend(vm, $controller('BaseApiController', { vm: vm }));
            angular.extend(vm, $controller('BaseLabelsController', { vm: vm }));
            vm.destroyed = false;
            vm.switchDomain = false;
            vm.switchDomainGroup = false;
            vm.search$ = new rx.BehaviorSubject('');
            vm.page$ = new rx.BehaviorSubject(1);
            vm.pageSize$ = new rx.BehaviorSubject(10);
            vm.customer = ng2SessionService.customer;
            vm.showLabels = activeFeatures.labels;
            vm.collection = [];
            vm.domainUpdateCollection = {};
            vm.subheaderTranslation = '';
            vm.modules = MON_MODULES;
            vm.allLabelsMap = {};
            vm.domainCount = 0;
            vm.scriptSetupGuideIsAvailable = ng2ActiveFeatureService.isFeatureActive('script_setup_guide');
            vm.hasOnDemandScans = ng2ActiveFeatureService.isFeatureActivePure('on_demand_scans');
            ng2CoreLoginService.getUser().then(function (user) {
                vm.user = user;
            }, angular.noop);
            const { search: term, page, page_size } = ng2ParamService.getParams();
            vm.search$.onNext(term);
            vm.page$.onNext(page);
            vm.pageSize$.onNext(page_size);
            vm.urlParams$ = rx.Observable.combineLatest(
                vm.page$,
                vm.pageSize$,
                vm.search$,
            ).subscribe(updateURLParams);
            getPage();
            getLabels();
            hotkeysInit();
        }

        function onDestroy () {
            vm.destroyed = true;
            vm.urlParams$?.dispose();
            onDestroy$.next();
            onDestroy$.complete();
        }

        function hotkeysInit () {
            hotkeys.bindTo($scope).add({
                combo: 'n',
                description: 'Create domain',
                callback: function (event) {
                    event.preventDefault();
                    createDomain();
                },
            });
        }

        function getPage () {
            const params = ng2ParamService.getParams();
            if (vm.selectedLabels && vm.selectedLabels.length > 0) {
                params['labels[]'] = vm.selectedLabels;
            }
            vm.promise = ng2DomainRepoService.getAll(params).then(
                function (data) {
                    vm.domainCount = data.total;
                    setSubHeaderDescription();
                    vm.collection = data;
                    requestGroupUpdatingStatus();
                    updateDropdowns();
                },
                function (error) {
                    ng2ErrorHandler.noopError(error);
                },
            );
        }

        function onSearchTable (search) {
            vm.search$.onNext(search);
            vm.page$.onNext(1);
            ng2MonEventsService.run('actionTrigger', { action: 'search', params: { search: search.value } });
        }

        function onPageChangeTable (page) {
            vm.page$.onNext(page);
            ng2MonEventsService.run('actionTrigger', { action: 'table-page-change', params: { page: page.value } });
        }

        function onPageSizeChangeTable (pageSize) {
            vm.pageSize$.onNext(pageSize);
            vm.page$.onNext(1);
            ng2MonEventsService.run('actionTrigger', { action: 'page-size-change', params: { pageSize: pageSize.value } });
        }

        function updateDropdown (index) {
            const domain = vm.collection[index];
            if (!domain) {
                return;
            }

            const canStopCrawl = canStopCrawlForDomain(domain.crawl_status?.step);
            const domainDropdown = [
                {
                    label: gettextCatalog.getString('Go to the dashboard'),
                    leftIcon: 'faHome',
                    action: ()=>goToDomain(domain),
                    eventParams: { action: 'admin-domain-dashboard' },
                    dataTestAttribute: 'go-to-dashboard-button',
                },
                {
                    label: gettextCatalog.getString('Script setup guide'),
                    leftIcon: 'faCode',
                    action: ()=>openScriptGuide(domain),
                    eventParams: { action: 'admin-domain-script-setup' },
                    shouldShow: vm.scriptSetupGuideIsAvailable,
                    dataTestAttribute: 'script-setup-button',
                },
                {
                    label: gettextCatalog.getString('Edit domain'),
                    leftIcon: 'faCog',
                    action: ()=>editDomain(domain, index),
                    eventParams: { action: 'admin-domain-edit' },
                    dataTestAttribute: 'edit-domain-button',
                },
                {
                    label: gettextCatalog.getString('Statistics excluded IP addresses'),
                    leftIcon: 'faChartLine',
                    action: ()=>editExcludedIps(domain),
                    eventParams: { action: 'admin-domain-excluded-ip' },
                    shouldShow: domain.features.statistics == true,
                    dataTestAttribute: 'exclude-ips-button',
                },
                {
                    label: gettextCatalog.getString('Start on demand scan'),
                    leftIcon: 'faSyncAlt',
                    action: ()=>startOnDemandScan(domain),
                    eventParams: { action: 'admin-domain-start-demand-scan' },
                    disabled: !vm.hasOnDemandScans || domain.crawl_status,
                    // eslint-disable-next-line max-len
                    tooltip: vm.hasOnDemandScans ? '' : gettextCatalog.getString('Your current plan does not include on-demand scans. Please reach out to your Monsido representative to learn more about this option'),
                    dataTestAttribute: 'start-on-demand-scan-button',
                },
                {
                    label: gettextCatalog.getString('Clone'),
                    leftIcon: 'faClone',
                    action: ()=>cloneDomain(domain),
                    eventParams: { action: 'admin-domain-clone' },
                    dataTestAttribute: 'clone-domain-button',
                },
                {
                    label: gettextCatalog.getString('Open Data Privacy'),
                    leftIcon: 'faLockAlt',
                    action: ()=>openDataPrivacy(domain),
                    shouldShow: ng2ActiveFeatureService.isFeatureActive('data_privacy', domain) && $filter('hasAccess')(vm.user, vm.modules.data_privacy),
                    dataTestAttribute: 'open-data-privacy-button',
                },
                {
                    divider: true,
                    shouldShow: domain.domain_groups.length > 0,
                },
                {
                    label: gettextCatalog.getString('Update all domain groups'),
                    leftIcon: 'faRedoAlt',
                    rightIcon: domain.running_groups ? 'faSpinner' : undefined,
                    spinRightIcon: true,
                    action: () => {
                        // eslint-disable-next-line max-len
                        const message = gettextCatalog.getString('Updating groups can take more than 30 minutes. During the operation you will not be able to create and update groups.');
                        ng2MonModalService.confirm(message).then(() => updateDomainGroup(domain));
                    },
                    disabled: domain.running_groups,
                    shouldShow: domain.domain_groups.length > 0,
                    dataTestAttribute: 'update-domain-group-button',
                },
                {
                    divider: true,
                },
                {
                    label: gettextCatalog.getString('Remove domain'),
                    leftIcon: 'faTrashAlt',
                    action: ()=>onConfirmDelete(domain),
                    eventParams: { action: 'admin-domain-remove' },
                    dataTestAttribute: 'delete-domain-button',
                },
                {
                    divider: true,
                    shouldShow: canStopCrawl,
                },
                {
                    label: gettextCatalog.getString('Stop current crawl'),
                    leftIcon: 'faStopCircle',
                    acton: ()=>{
                        const message = gettextCatalog.getString('Are you sure you want to stop current crawl?');
                        ng2MonModalService.confirm(message).then(()=>stopCurrentCrawl(domain));
                    },
                    eventParams: { action: 'admin-domain-stop-crawl' },
                    shouldShow: canStopCrawl,
                    dataTestAttribute: 'stop-current-crawl-button',
                },
            ];

            vm.collection[index].dropdown = domainDropdown.filter((option)=>option.shouldShow !== false).map(item => {
                return {
                    label: item.label,
                    leftIcon: item.leftIcon,
                    rightIcon: item.rightIcon,
                    spinRightIcon: item.spinRightIcon,
                    divider: item.divider,
                    disabled: item.disabled,
                    tooltip: item.tooltip,
                    action: ()=>{
                        if (item.eventParams) {
                            ng2MonEventsService.run('actionTrigger', item.eventParams);
                        }

                        if (item.action) {
                            item.action();
                        }
                    },
                    dataTestAttribute: item.dataTestAttribute,
                };
            });

            if (domain.domain_groups) {
                for (let j = 0; j < domain.domain_groups.length; j++) {
                    const domainGroup = domain.domain_groups[j];
                    const groupId = domainGroup.id;
                    const isUpdating = isGroupUpdating(domain.id, groupId);

                    const domainGroupDropdown = [
                        {
                            label: gettextCatalog.getString('Open group'),
                            leftIcon: 'faClone',
                            action: () => goToDomain(domain, domainGroup),
                            eventParams: { action: 'admin-domain-open-group' },
                        },
                        {
                            label: gettextCatalog.getString('Edit domain group'),
                            leftIcon: 'faCog',
                            action: () => editDomainGroup(domain, domainGroup),
                            eventParams: { action: 'admin-domain-edit-group' },
                        },
                        {
                            label: gettextCatalog.getString('Update domain group'),
                            leftIcon: 'faRedoAlt',
                            rightIcon: isUpdating ? 'faSpinner' : undefined,
                            spinRightIcon: true,
                            action: () => {
                                // eslint-disable-next-line max-len
                                const message = gettextCatalog.getString('Updating a group can take more than 30 minutes. During the operation you will not be able to create and update groups.');

                                ng2MonModalService.confirm(message)
                                    .then(() => updateSingleDomainGroup(domain.id, groupId));
                            },
                            disabled: isUpdating,
                        },
                        {
                            divider: true,
                        },
                        {
                            label: gettextCatalog.getString('Remove domain group'),
                            leftIcon: 'faTrashAlt',
                            action: () => onConfirmDeleteGroup(domain, domainGroup),
                            eventParams: { action: 'admin-domain-remove-group' },
                        },
                    ];
                    vm.collection[index].domain_groups[j].dropdown = domainGroupDropdown;
                }
            }
        }

        function getLabels () {
            LabelRepo.getLabels().then(function (data) {
                vm.allLabels = data;
                vm.allLabelsMap = Lodash.keyBy(data, 'id');
                return vm.allLabels;
            }, angular.noop);
        }

        function createDomain (domain) {
            var params = {
                body: FORM_CONSTANT.FORM_DOMAIN,
                size: 'md',
                data: {
                    domain: domain,
                },
            };

            const options = {
                params,
                callback: (updatedDomain) => {
                    if (updatedDomain) {
                        changedDomain();
                    }
                },
            };

            ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, options);
        }

        function editDomain (selectedDomain, index) {
            var params = {
                body: FORM_CONSTANT.FORM_DOMAIN,
                size: 'md',
                data: { domain: selectedDomain },
            };

            const options = {
                params,
                callback: () => {
                    vm.domainUpdateCollection[selectedDomain.id] = true;
                    ng2DomainService.get(selectedDomain.id)
                        .then(function (updatedDomain) {
                            if (vm.collection[index] && vm.collection[index].id === updatedDomain.id) {
                                vm.collection[index] = updatedDomain;
                                updateDropdown(index);
                            }
                        }, angular.noop)
                        .finally(function () {
                            delete vm.domainUpdateCollection[selectedDomain.id];
                        });
                },
            };

            ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, options);
        }

        function editDomainGroup (domain, domainGroup) {
            var params = {
                body: FORM_CONSTANT.FORM_DOMAIN_GROUPS_EDIT,
                size: 'lg',
                data: { domainGroup: domainGroup, domain: domain },
            };

            const options = {
                params,
                callback: (updatedDomainGroup) => {
                    if (updatedDomainGroup) {
                        changedDomain();
                    }
                },
            };

            ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, options);
        }

        function onConfirmDelete (domain) {
            // Message is put in here, because the message in the dom is not updated on search
            var msg =
                gettextCatalog.getString('You are about to delete the domain') +
                ' <span class=\'text-link\'>' +
                domain.title +
                '</span> ' +
                gettextCatalog.getString('please type') +
                ' <code>Delete</code> ' +
                gettextCatalog.getString('to confirm this action');

            msg +=
                '<div class=\'alert alert-danger inner-t-mini inner-b-mini inner-l-mini inner-r-mini outer-b-none outer-t-medium\'>' +
                gettextCatalog.getString('By deleting this domain all domain groups related to this domain, will also be deleted') +
                '</div>';

            ng2MonModalService.prompt(msg).then(function (result) {
                if ('delete' !== result.toLowerCase().trim()) {
                    return;
                }
                ng2DomainService.destroy(domain.id).then(function () {
                    if (domain.crawl_history) {
                        updateCustomer(domain);
                    } else {
                        changedDomain();
                    }
                    ng2TotalDomainsService.deleteDomain(domain.id);
                }, angular.noop);
            }, angular.noop);
        }

        function startOnDemandScan (domain) {
            if (domain.crawl_status) {
                return;
            }
            ng2MonModalService.confirm(gettextCatalog.getString('Are you sure you want to start on-demand scan?')).then(function () {
                ng2DomainService.rescan(domain.id).then(function () {
                    changedDomain();
                }, angular.noop);
            }, angular.noop);
        }

        function openScriptGuide (domain) {
            var params = {
                body: FORM_CONSTANT.FORM_DOMAIN_SCRIPT_GUIDE,
                size: 'md',
                data: { domain: domain },
            };

            const options = {
                params,
                callback: (updatedDomain) => {
                    if (updatedDomain) {
                        ng2MonEventsService.run('change-editedScriptBlock-status');
                        AdminDomainsService.updateDomainSettings(domain);
                    }
                },
            };

            ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, options);
        }

        function changedDomain () {
            getPage();
        }

        function onConfirmDeleteGroup (domain, domainGroup) {
            // Message is put in here, because the message in the dom is not updated on search
            var msg =
                gettextCatalog.getString('You are about to delete the domain group') +
                ' <span class=\'text-link\'>' +
                domainGroup.title +
                '</span> ' +
                gettextCatalog.getString('please type') +
                ' <code>Delete</code> ' +
                gettextCatalog.getString('to confirm this action');

            ng2MonModalService.prompt(msg).then(function (result) {
                if ('delete' !== result.toLowerCase().trim()) {
                    return;
                }

                domainGroupService.destroy(domain.id, domainGroup.id).then(function () {
                    if (domain.crawl_history) {
                        updateCustomer(domain);
                    } else {
                        changedDomain();
                    }
                }, angular.noop);
            }, angular.noop);
        }

        function updateCustomer (domain) {
            var updatedDocumentsCount = vm.customer.documents_count - domain.crawl_history.documents_count;
            var updatedHtmlPagesCount = vm.customer.html_pages_count - domain.crawl_history.page_count;
            var updatedCustomer = angular.copy(vm.customer);
            updatedCustomer.documents_count = updatedDocumentsCount;
            updatedCustomer.html_pages_count = updatedHtmlPagesCount;
            if (vm.customer.documents_count !== updatedDocumentsCount || vm.customer.html_pages_count !== updatedHtmlPagesCount) {
                accountService
                    .update(updatedCustomer)
                    .then(function (data) {
                        vm.customer = data;
                        changedDomain();
                    }, angular.noop);
            } else {
                changedDomain();
            }
        }

        function editExcludedIps (domain) {
            var params = {
                body: FORM_CONSTANT.FORM_DOMAIN_EXCLUDE_IP,
                size: 'md',
                data: { domainId: domain.id },
            };

            const options = {
                params,
                callback: () => {
                    changedDomain();
                },
            };

            ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, options);
        }

        function goToDomain (domain, domainGroup) {
            var page;
            vm.switchDomain = domain.id;
            var params = {
                domainId: domain.id,
            };
            if (domainGroup === undefined) {
                coreDomainService.removeDomainGroup();
            } else {
                coreDomainService.setDomainGroup(domainGroup);
                vm.switchDomainGroup = domainGroup.id;
                params.domain_group = domainGroup.id;
            }
            page = 'base.customer.domain.dashboard';

            $state
                .go(page, params)
                .then(function () {
                    ng2MonEventsService.run('changed-domain');
                }, angular.noop)
                .finally(function () {
                    vm.switchDomain = false;
                    vm.switchDomainGroup = false;
                });
        }

        function updateDomainLabels (domain) {
            var params = {};
            params.labels = domain.labels.map(function (label) {
                return label.id;
            });

            return ng2DomainService.update(domain.id, params);
        }

        function hasConstraintsOrExcludes (domain) {
            return domain.path_constraints.length + domain.link_excludes.length > 0;
        }

        function stopCurrentCrawl (domain) {
            ng2DomainService.stopCrawl(domain.id).then(function () {
                changedDomain();
            }, angular.noop);
        }

        function cloneDomain (domain) {
            const clone = cloneDeep(domain);

            clone.domain_users?.forEach(function (user) {
                delete user.id;
                return user;
            });
            clone.link_excludes = clone.link_excludes.map(function (exclude) {
                delete exclude.id;
                return exclude;
            });
            clone.path_constraints = clone.path_constraints.map(function (constraint) {
                delete constraint.id;
                return constraint;
            });
            clone.domain_groups = [];

            delete clone.scan.origTime;
            delete clone.crawl_history;
            delete clone.updated_at;
            delete clone.created_at;
            delete clone.crawl_status;
            delete clone.id;
            delete clone.url;
            delete clone.title;

            createDomain(new Domain(clone));
        }

        function openDataPrivacy (domain) {
            var params = {
                body: 'domainDataPrivacySettings',
                size: 'md',
                data: {
                    domain: domain,
                },
            };

            const options = {
                params,
            };

            ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, options);
        }

        function setSubHeaderDescription () {
            var params = {
                domains: vm.domainCount,
            };
            vm.subheaderTranslation = gettextCatalog.getString(
                'You have {{domains}} domain(s) on your account',
                params,
            );
        }

        // PRIVATE

        function onFiltersChanges (changes) {
            vm.updateFilters(changes);
            vm.getPage();
        }

        function updateURLParams () {
            ng2ParamService.setParams({
                page: vm.page$.value,
                page_size: vm.pageSize$.value,
                search: vm.search$.value,
            });

            vm.getPage();
        }

        async function updateDomainGroup (domain) {
            const domainId = domain.id;

            updateDomainRunningGroups(domainId, true);
            updateDropdowns();

            const groupIds = domain.domain_groups.map(group => group.id);

            if (!vm.updatingGroups[domainId]) {
                vm.updatingGroups[domainId] = new Set();
            }
            groupIds.forEach(id => {
                vm.updatingGroups[domainId].add(id);
            });

            await domainGroupService.updateDomainGroupRules(domainId);

            return new Promise(
                resolve => delayCheckGroupsUpdatingStatus(resolve, domainId, groupIds),
            );
        }

        async function updateSingleDomainGroup (domainId, groupId) {
            updateDomainRunningGroups(domainId, true);
            updateDropdowns();

            if (!vm.updatingGroups[domainId]) {
                vm.updatingGroups[domainId] = new Set();
            }

            vm.updatingGroups[domainId].add(groupId);

            await domainGroupService.updateSingleDomainGroupRules(domainId, groupId);

            return new Promise(
                resolve => delayCheckGroupsUpdatingStatus(resolve, domainId, [groupId]),
            );
        }

        function delayCheckGroupsUpdatingStatus (resolve, domainId, groupIds) {
            setTimeout(() => {
                if (!vm.destroyed) {
                    checkGroupsUpdatingStatus(domainId, groupIds);
                }
                resolve();
            }, 2000); // gives 2s to Backend to react
        }

        function checkGroupsUpdatingStatus (domainId, groupIds) {
            ng2DomainGroupUpdateInfoService
                .getUpdateStatus(domainId, groupIds, onDestroy$)
                .subscribe((updatingGroupIds) => {
                    if (!vm.destroyed) {
                        if (updatingGroupIds.size) {
                            vm.updatingGroups[domainId] = new Set(updatingGroupIds);
                        } else {
                            delete vm.updatingGroups[domainId];
                            updateDomainRunningGroups(domainId, false);
                        }

                        // triggerring digest
                        vm.collection = cloneCollection(vm.collection);
                        updateDropdowns();
                    }
                });
        }

        function updateDomainRunningGroups (domainId, isRunning) {
            vm.collection.forEach(d => {
                if (d.id === domainId) {
                    d.running_groups = isRunning;
                }
            });
            vm.collection = cloneCollection(vm.collection);
        }

        function requestGroupUpdatingStatus () {
            vm.collection.forEach(domain => {
                if (domain.running_groups) {
                    const groupIds = domain.domain_groups.map(group => group.id);
                    checkGroupsUpdatingStatus(domain.id, groupIds);
                }
            });
        }

        function isGroupUpdating (domainId, groupId) {
            const updatingGroups = vm.updatingGroups[domainId];
            return updatingGroups && updatingGroups.has(groupId);
        }

        function cloneCollection (collection) {
            const { total, currentPage, flattenDomains, pageHits, perPage } = collection;
            const result = Lodash.cloneDeep(collection);
            result.total = total;
            result.currentPage = currentPage;
            result.pageHits = pageHits;
            result.perPage = perPage;
            result.flattenDomains = flattenDomains;
            return result;
        }

        function canStopCrawlForDomain (crawlStatusStep) {
            return [
                CRAWL_STATUS.started,
                CRAWL_STATUS.queued,
                CRAWL_STATUS.backoff,
                CRAWL_STATUS.retry,
            ].indexOf(
                crawlStatusStep,
            ) !== -1;
        }
    }
})();
