(function () {
    'use strict';
    /**
     * @memberof services.entities
     * @ngdoc factory
     * @name HeatmapApiService
     * @description Service to handle domains
     */
    angular.module('services.entities').factory('HeatmapApiService', HeatmapApiService);

    HeatmapApiService.$inject = ['ng2HeatmapsRepoService', 'ng2DemoHasAccessService', 'DemoHeatmapsRepo'];
    /* @ngInject*/
    function HeatmapApiService (ng2HeatmapsRepoService, ng2DemoHasAccessService, DemoHeatmapsRepo) {
        return {
            create: create,
            destroy: destroy,
            getAllFromCustomer: getAllFromCustomer,
        };

        function create (domainId, params) {
            if (ng2DemoHasAccessService.isDemoHeatmaps()) {
                return DemoHeatmapsRepo.create(domainId, params);
            }

            return ng2HeatmapsRepoService.create(domainId, params);
        }

        function destroy (domainId, ruleId) {
            if (ng2DemoHasAccessService.isDemoHeatmaps()) {
                return DemoHeatmapsRepo.destroy(domainId, ruleId);
            }

            return ng2HeatmapsRepoService.destroy(domainId, ruleId);
        }

        function getAllFromCustomer (params) {
            if (ng2DemoHasAccessService.isDemoHeatmaps()) {
                return DemoHeatmapsRepo.getAllFromCustomer(params);
            }

            return ng2HeatmapsRepoService.getAllFromCustomer(params);
        }
    }
})();
