(function () {
    'use strict';

    angular.module('pages.domain').component('domainDashboardSeo', {
        templateUrl: 'app/pages/domain/charts/seo/seo.html',
        controller: DashboardSEOController,
        controllerAs: 'vm',
        bindings: {
            historyData: '=',
        },
    });

    DashboardSEOController.$inject = [
        'SeoRepo',
        '$filter',
        'gettextCatalog',
        '$q',
        'complianceService',
        'ng2SessionService',
        'ChangesHistoryService',
        'ng2ActiveFeatureService',
        'ng2PropertiesSumService',
    ];
    /* @ngInject */
    function DashboardSEOController (
        SeoRepo,
        $filter,
        gettextCatalog,
        $q,
        complianceService,
        ng2SessionService,
        ChangesHistoryService,
        ng2ActiveFeatureService,
        ng2PropertiesSumService,
    ) {
        var vm = this;
        vm.$onInit = activate;
        vm.getHistoryCenterParameters = getHistoryCenterParameters;

        function activate () {
            vm.seoCategoryErrors = {};
            vm.loading = true;
            vm.doughnutLabels = ['', ''];
            vm.colors = ['#4dc0bd', '#dcdcdc'];
            vm.showHistory = ng2ActiveFeatureService.isFeatureActivePure('history_center_all');
            vm.total = 0;
            vm.totalPages = 0;
            vm.domain = ng2SessionService.domain;
            var promises = [getSummary(), getCheckpoints()];
            $q.all(promises).then(function (values) {
                setupDoughnutChart(values[0], values[1]);
                vm.loading = false;
            }, angular.noop);
        }

        function getSummary () {
            return SeoRepo.getSummary();
        }

        function getCheckpoints () {
            var params = {
                page_size: 0,
            };
            return SeoRepo.getAll(params);
        }

        function setupDoughnutChart (summary, checkpoints) {
            var percentage = complianceService.seoCompliancePercentage(summary, checkpoints, vm.historyData);
            vm.doughnutData = complianceService.seoCompliance(summary, checkpoints, vm.historyData);
            vm.doughnutOptions = {
                centerText: {
                    percentageLabel: $filter('monNumber')(percentage / 100, '0.[00] %'),
                    totalLabel: gettextCatalog.getString('Overall compliance'),
                    subLabel: gettextCatalog.getString('achieved'),
                    totalFontSize: 150,
                    subFontSize: 150,
                },
                cutoutPercentage: 80,
                tooltips: {
                    enabled: false,
                },
                hover: {
                    mode: null,
                },
            };
            vm.seoCategoryErrors.alert = ng2PropertiesSumService.sum(summary, ['seo_errors_alerts_count']);
            vm.seoCategoryErrors.low = ng2PropertiesSumService.sum(summary, ['seo_errors_infos_count']);
            vm.seoCategoryErrors.medium = ng2PropertiesSumService.sum(summary, ['seo_errors_warnings_count']);
            vm.seoCategoryErrors.technical = ng2PropertiesSumService.sum(summary, ['seo_errors_technicals_count']);
            vm.seoErrors = vm.seoCategoryErrors.alert + vm.seoCategoryErrors.low + vm.seoCategoryErrors.medium;
        }

        function getHistoryCenterParameters (module) {
            return ChangesHistoryService.getHistoryCenterParameters(module);
        }
    }
})();
