(function () {
    'use strict';

    angular.module('app.components.pageDetails').component('pageDetails', {
        templateUrl: 'app/components/pagedetails/details/details.html',
        controller: PageDetailsController,
        controllerAs: 'vm',
        bindings: {
            page: '<',
            target: '<',
            subTarget: '<',
        },
    });

    PageDetailsController.$inject = [
        'LabelRepo',
        'ng2SessionService',
        'gettextCatalog',
        '$filter',
        '$scope',
        'MON_MODULES',
        '$stateParams',
        'ng2SpellingToolRepoService',
        'pageService',
        'ng2CoreLoginService',
        'ng2ActiveFeatureService',
        'ng2MonModalService',
        'ng2PageRepoService',
        'rx',
    ];
    /* @ngInject */
    function PageDetailsController (
        LabelRepo,
        ng2SessionService,
        gettextCatalog,
        $filter,
        $scope,
        MON_MODULES,
        $stateParams,
        ng2SpellingToolRepoService,
        pageService,
        ng2CoreLoginService,
        ng2ActiveFeatureService,
        ng2MonModalService,
        ng2PageRepoService,
        rx,
    ) {
        var vm = this;

        vm.setActiveSection = setActiveSection;
        vm.dashboardNavigateTo = dashboardNavigateTo;
        vm.refreshPage = refreshPage;
        vm.updatePage = updatePage;
        vm.getLabels = getLabels;
        vm.openInCMSButtonText = openInCMSButtonText;
        vm.performanceShowSection = performanceShowSection;
        vm.rescan = rescan;
        vm.$onInit = activate;
        vm.$onDestroy = onDestroy;
        vm.manualScanned = manualScanned;
        vm.observeSinglePageScanStatus = observeSinglePageScanStatus;

        function activate () {
            vm.scanStatusObj = null;
            vm.target = vm.target || $stateParams.target || null;
            vm.links = [];
            vm.subTarget = vm.subTarget || $stateParams.sub_target || null;
            vm.languages = [];
            vm.cmsUrl = '';
            getCmsUrl();
            getPage(true);
            vm.ng2SessionService = ng2SessionService;
            vm.isAdmin = ng2SessionService.hasRole('customer_admin');
            $scope.$on('update-page', function () {
                getPage(false);
            });
            vm.checkJobTimeout = null;
            vm.checkIfSinglePageScanIsRunning = false;
            vm.scanStatus = null;
            getLabels();
            getLanguages();
            manualScanned();
            if (vm.page.latest_page_scan_status === 'failed') {
                getScanStatus();
            }
            if (
                vm.page.latest_page_scan_status &&
                !(vm.page.latest_page_scan_status === 'completed' || vm.page.latest_page_scan_status === 'failed')
            ) {
                vm.checkIfSinglePageScanIsRunning = true;
                observeSinglePageScanStatus();
            }
        }

        function getScanStatus () {
            return ng2PageRepoService.getScanStatus(vm.page.id).then(function (data) {
                vm.scanStatusObj = data;
            }, angular.noop);
        }

        function onDestroy () {
            if (vm.scanStatus && !vm.scanStatus.isDisposed) {
                vm.scanStatus.dispose();
                vm.scanStatus = null;
            }
        }

        function dashboardNavigateTo (params) {
            vm.target = params.target;
            vm.subTarget = params.subTarget;
            setupActive();
        }

        function getPage (runSetupActive) {
            pageService.get(vm.page.id).then(function (page) {
                vm.page = page;
                setupLinks(runSetupActive);
            }, angular.noop);
        }

        function refreshPage (target) {
            vm.target = target;
            getPage(true);
        }

        function setActiveSection (section) {
            vm.active = section;
        }

        function setupActive () {
            var selected = null;
            if (vm.target) {
                for (var i = 0; i < vm.links.length; i++) {
                    if (vm.links[i].component === vm.target) {
                        selected = vm.links[i];
                    }
                }
            }
            vm.active = selected || vm.links[0];
        }

        function setupLinks (runSetupActive) {
            ng2CoreLoginService.getUser().then(function (user) {
                user = user === null ? ng2SessionService.me : user;
                vm.links = [];
                var links = [
                    {
                        name: gettextCatalog.getString('Page dashboard'),
                        count: '',
                        component: 'page-details-section-dashboard',
                        icon: $filter('monIcons')(['MENUES', 'DASHBOARD']),
                        module: null,
                    },
                    {
                        name: gettextCatalog.getString('Quality Assurance'),
                        count: vm.page.getQualityNotificationsCount(),
                        component: 'page-details-section-qa',
                        show: ng2ActiveFeatureService.isFeatureActivePure('qa_module'),
                        icon: $filter('monIcons')(['MENUES', 'QA']),
                        module: MON_MODULES.qa,
                    },
                    {
                        name: gettextCatalog.getString('Accessibility'),
                        count: vm.page.accessibility_checks_with_errors_count,
                        component: 'page-details-section-accessibility',
                        show: ng2SessionService.domain.features.accessibility,
                        icon: $filter('monIcons')(['MENUES', 'ACCESSIBILITY']),
                        module: MON_MODULES.accessibility,
                    },
                    {
                        name: gettextCatalog.getString('SEO'),
                        count: vm.page.seo_issues_count,
                        component: 'page-details-section-seo',
                        show: ng2ActiveFeatureService.isFeatureActivePure('seo_all'),
                        icon: $filter('monIcons')(['MENUES', 'SEO']),
                        module: MON_MODULES.seo,
                    },
                    {
                        name: gettextCatalog.getString('Statistics'),
                        count: '',
                        show: allowStatistics(),
                        component: 'page-details-section-statistics',
                        icon: $filter('monIcons')(['MENUES', 'STATISTICS']),
                        module: MON_MODULES.statistics,
                    },
                    {
                        name: gettextCatalog.getString('Data Privacy'),
                        count: '',
                        component: 'page-details-section-data-privacy',
                        icon: $filter('monIcons')(['MENUES', 'DATA_PRIVACY']),
                        hasAccess: $filter('monCustomerHasPlan')('demo') || ng2ActiveFeatureService.isFeatureActive(MON_MODULES.data_privacy),
                        module: MON_MODULES.data_privacy,
                    },
                    {
                        name: gettextCatalog.getString('Inventory'),
                        count: '',
                        show: ng2ActiveFeatureService.isFeatureActivePure('inventory_all'),
                        component: 'page-details-section-inventory',
                        icon: $filter('monIcons')(['MENUES', 'INVENTORY']),
                        module: MON_MODULES.inventory,
                    },
                    {
                        name: gettextCatalog.getString('Performance'),
                        count: '',
                        component: 'page-details-section-page-performance',
                        icon: $filter('monIcons')(['MENUES', 'PAGE_PERFORMANCE']),
                        module: null,
                        hasAccess:
                            ng2ActiveFeatureService.isFeatureActive('page_performance') && $filter('hasAccess')(user, MON_MODULES.performance),
                        isDisabled: !ng2ActiveFeatureService.isFeatureActive('page_page_performance', null, vm.page),
                        isBeta: false,
                    },
                ];
                links = addFeatures(links);
                angular.forEach(links, function (link) {
                    if (
                        (link.module === null || $filter('hasAccess')(user, link.module)) &&
                        (typeof link.hasAccess !== 'boolean' || link.hasAccess)
                    ) {
                        vm.links.push(link);
                    }
                });

                if (runSetupActive) {
                    setupActive();
                }
            }, angular.noop);
        }

        function addFeatures (links) {
            if (ng2ActiveFeatureService.isFeatureActivePure('policies_all')) {
                links.splice(1, 0, {
                    name: gettextCatalog.getString('Policies'),
                    count: vm.page.policy_violation_count + vm.page.policy_required_count + vm.page.policy_search_count,
                    component: 'page-details-section-policies',
                    icon: $filter('monIcons')(['MENUES', 'POLICIES']),
                    module: MON_MODULES.policies,
                });
            }

            return links;
        }

        function getLabels () {
            LabelRepo.getLabels().then(function (data) {
                vm.allLabels = data;
            }, angular.noop);
        }

        function updatePage (page) {
            var params = {
                language: vm.page.language,
                labels: page.labels.map(function (label) {
                    return label.id;
                }),
            };
            pageService.update(page, params);
        }

        function openInCMSButtonText () {
            var text = gettextCatalog.getString('CMS');
            if (ng2SessionService.domain) {
                switch (ng2SessionService.domain.cms) {
                    case 'presence':
                        text = gettextCatalog.getString('Presence');
                        break;
                    case 'gabbart':
                        text = gettextCatalog.getString('Harmony CMS');
                        break;
                }
            }

            return text;
        }

        function getLanguages () {
            ng2SpellingToolRepoService.get().then(function (data) {
                vm.languages = data;
            }, angular.noop);
        }

        function allowStatistics () {
            if ($filter('monCustomerHasPlan')('demo')) {
                return true;
            }
            if (ng2SessionService.domain && ng2SessionService.domainGroup === null) {
                return ng2SessionService.domain.features.statistics;
            } else if (ng2SessionService.domain && ng2SessionService.domainGroup !== null) {
                return (
                    ng2SessionService.domain.features.statistics &&
                    ng2SessionService.customer !== null &&
                    ng2SessionService.customer.plan_traits.domain_group_statistics
                );
            }
            return false;
        }

        function performanceShowSection () {
            return (
                vm.active &&
                ng2ActiveFeatureService.isFeatureActive('page_page_performance', null, vm.page) &&
                vm.active.component === 'page-details-section-page-performance'
            );
        }

        function rescan () {
            vm.checkIfSinglePageScanIsRunning = true;
            pageService.rescanPageDetail(vm.page.id).then(function (data) {
                observeSinglePageScanStatus();
                ng2MonModalService
                    .alert(
                        gettextCatalog.getString('Re-scanning of issues for this page has started and will be finished in a few minutes.'),
                    )
                    .then(angular.noop, angular.noop);
            }, angular.noop);
        }

        function getCmsUrl () {
            pageService.getCmsUrlPageDetail(vm.page.id).then(function (data) {
                return vm.cmsUrl = data.cms_url;
            }, angular.noop);
        }

        /**
         * If a single page scan time is newer than the last scan in our database, and the blue notification bar will be displayed.
         */
        function manualScanned () {
            if (ng2SessionService.domain === null || !ng2SessionService.domain.crawl_history) {
                return false;
            }

            var lastScan = ng2SessionService.domain.crawl_history.post_processing_done_at;
            var lastManuelScan = vm.page.lastcheck;
            if (!lastManuelScan) {
                return false;
            }
            return moment(lastManuelScan).isAfter(lastScan);
        }

        function observeSinglePageScanStatus () {
            vm.scanStatus = rx.Observable.interval(30000).subscribe(function () {
                return ng2PageRepoService.getScanStatus(vm.page.id).then(function (data) {
                    vm.scanStatusObj = data;
                    if (data.status === 'completed' || data.status === 'failed') {
                        vm.checkIfSinglePageScanIsRunning = false;
                        vm.scanStatus.dispose();
                        vm.scanStatus = null;
                        getPage(false);
                    }
                    return data;
                }, angular.noop);
            });
        }
    }
})();
