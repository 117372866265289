import { Injectable } from '@angular/core';
import { ApiClient } from '@monsido/modules/endpoints/api/api-client';
import { Page } from '@monsido/modules/page-details/models/page.interface';
import { SessionService } from '@monsido/core/session/session.service';
import { RequestParamsType } from '@monsido/ng2/external/http/options';

@Injectable({
    providedIn: 'root',
})
export class PageRepo {
    constructor (private client: ApiClient,
        private sessionService: SessionService) {}

    getPage (pageId: number): Promise<Page> {
        if (this.sessionService.domain?.id) {
            return this.client.getPromise(`domains/${this.sessionService.domain.id}/pages/${pageId}`);
        }

        throw (new Error('No domain id present'));
    }

    update (page: Page, params: RequestParamsType): Promise<void> {
        params = params || {};
        params = this.sessionService.setGroupId(params);

        if (this.sessionService.domain?.id) {
            return this.client.patchPromise(`domains/${this.sessionService.domain.id}/pages/${page.id}`, page, params);
        }

        throw (new Error('No domain id present'));
    }

}
