(function () {
    'use strict';

    angular.module('pages.qa.readability.summary').component('readabilitySummaryResults', {
        templateUrl: 'app/pages/qa/readability/summary/results/results.html',
        controller: ReadabilitySummaryResultsController,
        controllerAs: 'vm',
        bindings: {
            summary: '<',
        },
    });

    ReadabilitySummaryResultsController.$inject = ['$filter', 'gettextCatalog', 'READABILITY_HELPER', 'ng2PageRepoService', 'QAReadabilityService'];

    function ReadabilitySummaryResultsController ($filter, gettextCatalog, READABILITY_HELPER, ng2PageRepoService, QAReadabilityService) {
        var vm = this;
        vm.$onInit = activate;
        vm.$onChanges = changes;
        function activate () {
            getPages().then(function (values) {
                vm.totalPageCount = parseInt(values.total, 10);
                vm.type = vm.summary.test;
                setupDoughnutChart();
                setupBarChart();
            }, angular.noop);
        }

        function changes () {
            if (vm.type && vm.summary) {
                setupDoughnutChart();
                setupBarChart();
            }
        }

        function getPages () {
            var params = {
                page_size: 1,
                page: 1,
            };

            return ng2PageRepoService.getAll(params);
        }

        function setupDoughnutChart () {
            vm.barLabels = [];
            vm.barSeries = ['Pages'];
            vm.barOptions = {
                legend: {
                    display: true,
                    position: 'bottom',
                    labels: {
                        boxWidth: 0,
                        padding: 0,
                    },
                },
                scales: {
                    xAxes: [
                        {
                            gridLines: {
                                offsetGridLines: true,
                                display: false,
                            },
                            ticks: {
                                beginAtZero: true,
                                suggestedMax: 4,
                                maxTicksLimit: 8,
                                callback: function (value) {
                                    return $filter('numeraljs')(value, '0,0');
                                },
                                min: 0,
                            },
                        },
                    ],
                },
            };
            vm.barOverrides = [
                {
                    label: 'Pages',
                    backgroundColor: [],
                    borderColor: [],
                },
            ];
            vm.barColors = ['#239CF9'];
            var s1 = [];

            var destributions = vm.summary.plain_distribution;
            var level;
            var subTitle;
            var label;

            for (var i = 0; i < READABILITY_HELPER[vm.type].length; i++) {
                level = READABILITY_HELPER[vm.type][i];
                subTitle = QAReadabilityService.getReadabilityLevelSubTitle(level, vm.summary.test);
                if (subTitle) {
                    label = [$filter('translateReadability')(level, vm.type), '(' + subTitle + ')'];
                } else {
                    label = $filter('translateReadability')(level, vm.type);
                }
                vm.barLabels.push(label);
                if (destributions[level]) {
                    s1.push(destributions[level]);
                } else {
                    s1.push(0);
                }
                vm.barOverrides[0].backgroundColor.push('rgba(35,156,249, 1)');
                vm.barOverrides[0].borderColor.push('rgba(35,156,249, 1)');
            }
            vm.barData = [s1];
        }

        function setupBarChart () {
            var _sortedDistribution = sortedDistribution(vm.summary.distribution)[0];
            var pagesWithHeightsDistribution = _sortedDistribution && _sortedDistribution.page_count;
            vm.doughnutLabels = ['Pages in compliance', 'Pages with errors'];
            vm.doughnutColors = ['#239CF9', '#DCDCDC'];

            vm.doughnutData = [pagesWithHeightsDistribution, vm.totalPageCount - pagesWithHeightsDistribution];
            // If the result isn't a number(NaN), so return 0.
            var result = pagesWithHeightsDistribution / vm.totalPageCount;
            result = isFinite(result) ? result : 0;
            var percentage = $filter('monNumber')(result, '0.[00] %');
            vm.doughnutOptions = {
                centerText: {
                    percentageLabel: percentage,
                    totalLabel: '  ', // Keep it, otherwise the text on the doughnut disappears
                    subLabel:
                        '(' +
                        gettextCatalog.getString('Affects') +
                        ' ' +
                        pagesWithHeightsDistribution +
                        ' ' +
                        $filter('translateWithPlural')('page', pagesWithHeightsDistribution, 'pages') +
                        ')',
                },
                cutoutPercentage: 68,
                tooltips: {
                    callbacks: {
                        label: function (tooltipItem, data) {
                            return gettextCatalog.getString('{{pageCount}} Pages', {
                                pageCount: $filter('numeraljs', '0,0')(data.datasets[0].data[tooltipItem.index]),
                            });
                        },
                    },
                },
            };
        }

        function sortedDistribution (distribution) {
            return distribution.sort(function (a, b) {
                if (a.page_count < b.page_count) {
                    return 1;
                }
                if (a.page_count > b.page_count) {
                    return -1;
                }
                return 0;
            });
        }
    }
})();
