import { map } from 'rxjs/operators';

(function () {
    'use strict';

    angular.module('services.api').factory('DomainGroupsRepo', DomainGroupsRepo);

    DomainGroupsRepo.$inject = [
        'ng2ApiService',
        'ng2SessionService',
        'ng2MonEventsService',
        'DomainGroupModel',
        'CrawlModel',
    ];
    /* @ngInject */
    function DomainGroupsRepo (ng2ApiService, ng2SessionService, ng2MonEventsService, DomainGroupModel, CrawlModel) {
        var service = {
            get,
            getAll,
            create,
            update,
            getHistory,
            getHistoryObservable,
            destroy,
        };

        return service;

        // /////////////

        function getAll (domainId, params, headers) {
            params = params || {};
            domainId = domainId || ng2SessionService.getDomainId(params);

            return ng2ApiService
                .get(`domains/${domainId}/domain_groups`, params, headers)
                .then(toModels);
        }

        function get (domainId, id, params, headers) {
            params = params || {};
            domainId = domainId || ng2SessionService.getDomainId(params);

            return ng2ApiService
                .get(`domains/${domainId}/domain_groups/${id}`, params, headers)
                .then(toModel);
        }

        function create (domain, params, headers) {
            const domainId = domain.id || ng2SessionService.getDomainId(params);

            return ng2ApiService
                .post(`domains/${domainId}/domain_groups`, params, headers)
                .then(function (group) {
                    ng2MonEventsService.run('domainGroupAdded', { domain, group });
                    return group;
                }, angular.noop)
                .then(toModel);
        }

        function update (domain, group, headers) {
            const domainId = domain.id || ng2SessionService.getDomainId();

            return ng2ApiService
                .patch(`domains/${domainId}/domain_groups/${group.id}`, group, null, headers)
                .then(function (groupArg) {
                    ng2MonEventsService.run('domainGroupUpdated', { domain, group: groupArg });
                    return groupArg;
                }, angular.noop)
                .then(toModel);
        }

        function destroy (domainId, id) {
            domainId = domainId || ng2SessionService.getDomainId();
            return ng2ApiService.delete(`domains/${domainId}/domain_groups/${id}`);
        }

        function getHistory (params) {
            params = params || {};
            const domainId = ng2SessionService.getDomainId(params);
            const domainGroupId = ng2SessionService.getGroupId(params);
            const route = `domains/${domainId}/domain_groups/${domainGroupId}/history`;

            return ng2ApiService
                .get(route, params)
                .then(toCrawlModels);
        }

        function getHistoryObservable (params, canceler$) {
            params = params || {};
            const domainId = ng2SessionService.getDomainId(params);
            const domainGroupId = ng2SessionService.getGroupId(params);
            const route = `domains/${domainId}/domain_groups/${domainGroupId}/history`;

            return ng2ApiService
                .getObservable(route, params, {}, canceler$)
                .pipe(map(toCrawlModels));
        }

        // PROTECTED

        function toModels (models) {
            for (let i = 0; i < models.length; i++) {
                models[i] = toModel(models[i]);
            }

            return models;
        }

        function toModel (model) {
            return model ? new DomainGroupModel(model) : {};
        }

        function toCrawlModels (models) {
            for (let i = 0; i < models.length; i++) {
                models[i] = new CrawlModel(models[i]);
            }

            return models;
        }
    }
})();
