import { Injectable } from '@angular/core';
import { ApiService } from '@client/app/services/api/api-service/api.service';
import { SessionService } from '@monsido/ng2/core/session/session.service';

export type SummaryRepoParamsType = {
    group_id?: number;
};

@Injectable({
    providedIn: 'root',
})
export class DataPrivacySummaryRepo {
    constructor (
        private apiService: ApiService,
        private sessionService: SessionService,
    ) {}

    getSummary (domainId: number, params: SummaryRepoParamsType = {}): Promise<unknown> {
        params = this.sessionService.setGroupId(params);

        return this.apiService.get(`domains/${domainId}/data_protection/summary`, params);
    }
}
