import { Injectable } from '@angular/core';
import { ApiService } from '@client/app/services/api/api-service/api.service';
import { SessionService } from '@monsido/ng2/core/session/session.service';

export type RulesetRepoParamsType = {
    group_id?: number;
};

@Injectable({
    providedIn: 'root',
})
export class DataPrivacyRulesetRepo {
    constructor (
        private apiService: ApiService,
        private sessionService: SessionService,
    ) {}

    getAll (params: RulesetRepoParamsType): Promise<unknown> {
        params = params || {};
        params = this.sessionService.setGroupId(params);
        const domainId = this.sessionService.getDomainId(params);

        return this.apiService.get(
            `domains/${domainId}/data_protection/custom_ruleset`,
            params,
        );
    }

    getAllForPage (pageId: number, params: RulesetRepoParamsType): Promise<unknown> {
        params = params || {};
        params = this.sessionService.setGroupId(params);
        const domainId = this.sessionService.getDomainId(params);

        return this.apiService.get(
            `domains/${domainId}/pages/${pageId}/data_protection/custom_ruleset`,
            params,
        );
    }
}
