import { Injectable } from '@angular/core';
import { ApiService } from '@client/app/services/api/api-service/api.service';
import { SessionService } from '@monsido/ng2/core/session/session.service';
import { RequestHeadersType, RequestParamsType } from '@monsido/ng2/external/http/options';

export type ConsentType = {
    anon_ip: string,
    country_code:string,
    page_url: string,
    session_id:string,
    tc_string: string,
    timestamp: string,
    user_agent: string
}

@Injectable({
    providedIn: 'root',
})
export class CookieBannerRepoService {

    constructor (private apiService: ApiService, private sessionService: SessionService) { }

    getConsents (params: RequestParamsType, config: RequestHeadersType): Promise<ConsentType[]> {
        config = config || {};
        params = params || {};

        if (!params.group_id) {
            params = this.sessionService.setGroupId(params);
        }

        return this.apiService.get('domains/' + this.sessionService.getDomainId(params) + '/cookie_banner/consents', params, config);
    }

    getStatistics (params: RequestParamsType, config: RequestHeadersType): Promise<ConsentType[]> {
        config = config || {};
        params = params || {};
        return this.apiService.get('domains/' + this.sessionService.getDomainId(params) + '/cookie_banner/statistics', params, config);
    }
}
