(function () {
    'use strict';

    angular.module('pages.dashboard').component('dashboard', {
        templateUrl: 'app/pages/dashboard/dashboard.html',
        controller: DashboardController,
        controllerAs: 'vm',
    });

    DashboardController.$inject = [
        'ng2DomainRepoService',
        '$interval',
        'version',
        'ng2SessionService',
        'environment',
        '$controller',
        'gettextCatalog',
        'rx',
        '$location',
        'ParamService',
        'ShortcutService',
        'ng2CoreLoginService',
        'ng2ExtensionService',
        'intercomService',
        'LabelRepo',
        'Lodash',
        'domainGroupService',
    ];

    /* @ngInject */
    function DashboardController (
        ng2DomainRepoService,
        $interval,
        version,
        ng2SessionService,
        environment,
        $controller,
        gettextCatalog,
        rx,
        $location,
        ParamService,
        ShortcutService,
        ng2CoreLoginService,
        ng2ExtensionService,
        intercomService,
        LabelRepo,
        Lodash,
        domainGroupService,
    ) {
        var vm = this;

        vm.$onInit = activate;
        vm.$onDestroy = destroyTimer;
        vm.getPage = getPage;
        vm.getIcon = getIcon;
        vm.sendIntercomMsg = sendIntercomMsg;
        vm.isIntercomChat = isIntercomChat;
        vm.onFiltersChanges = onFiltersChanges;
        vm.allLabelsMap = {};

        async function activate () {
            angular.extend(vm, $controller('BaseApiController', { vm: vm }));
            angular.extend(vm, $controller('BaseLabelsController', { vm: vm }));
            vm.subheaderTranslation = '';
            vm.chatSystem = ng2SessionService.getChatSystem();
            vm.environment = environment;
            vm.version = version;
            vm.search = '';
            vm.hasExtension = null;
            vm.showVersion = false;
            vm.domainCount = 0;
            vm.currentSort = {
                by: 'favorite',
                direction: 'asc',
            };
            vm.hasExtension = null;
            vm.domainObservable = new rx.BehaviorSubject([]);
            vm.allLabelsMap = getLabels();
            loadLocations();
            getPage(true);

            startAutoReload();
            vm.monsidoPath = ng2CoreLoginService.getApiPath();
            ng2ExtensionService.isInstalled().then(function (res) {
                vm.hasExtension = res;
            });
            ShortcutService.shortcuts.subscribe(function (shortcuts) {
                if (Array.isArray(shortcuts)) {
                    vm.shortcuts = shortcuts
                        .filter(function (shortcut) {
                            return shortcut.on_dashboard;
                        })
                        .sort(function (a, b) {
                            return a.private && !b.private ? 1 : !a.private && b.private ? -1 : 0;
                        });
                }
            });
        }

        function isIntercomChat () {
            return vm.chatSystem === 'intercom';
        }

        function sendIntercomMsg (msg) {
            intercomService.addMsg(msg);
        }

        function loadLocations () {
            var params = $location.search();
            if (typeof params.page !== 'undefined') {
                vm.page = params.page;
            }

            if (typeof params.page_size !== 'undefined') {
                vm.pageSize = params.page_size;
            }

            if (typeof params.search !== 'undefined') {
                vm.search = params.search;
            }

            if (typeof params.sort_by !== 'undefined') {
                vm.currentSort.by = params.sort_by;
            }

            if (typeof params.sort_dir !== 'undefined') {
                vm.currentSort.direction = params.sort_dir;
            }
        }

        function getSubHeaderDescription () {
            var params = {
                domains: vm.domainCount,
            };
            vm.subheaderTranslation = gettextCatalog.getString(
                'You have {{domains}} domain(s) on your account',
                params,
            );
        }

        function destroyTimer () {
            if (typeof vm.timerPromise === 'object') {
                $interval.cancel(vm.timerPromise);
            }
        }

        function startAutoReload () {
            vm.timerPromise = $interval(function () {
                getPage(false);
            }, 30000);
        }

        async function getLabels () {
            try {
                const data = await LabelRepo.getLabels();
                vm.allLabelsMap = Lodash.keyBy(data, 'id');
                return vm.allLabelsMap;
            } catch (e) {
                angular.noop(e);
            }
        }

        function getPage (returnPromise) {
            var params = {
                page_size: vm.pageSize,
                page: vm.page,
                sort_by: vm.currentSort.by,
                sort_dir: vm.currentSort.direction,
                search: vm.search || null,
            };
            if (vm.selectedLabels && vm.selectedLabels.length > 0) {
                params['labels[]'] = vm.selectedLabels;
            }
            ParamService.setParams(params);

            var promise = ng2DomainRepoService.getAll(params).then(function (domains) {
                vm.domainCount = domains.total;
                getSubHeaderDescription();
                for (let i = 0; i < domains.length; i++) {
                    const domainGroups = domains[i].domain_groups;

                    if (typeof domains[i].crawl_history === 'object' && domains[i].crawl_history !== null) {
                        domains[i].crawl_history.qa_count =
                            domains[i].crawl_history.dead_links_count +
                            domains[i].crawl_history.dead_images_count +
                            domains[i].crawl_history.spelling_errors_confirmed_count;

                        domains[i].crawl_history.seo_count =
                            domains[i].crawl_history.seo_errors_alerts_count +
                            domains[i].crawl_history.seo_errors_infos_count +
                            domains[i].crawl_history.seo_errors_warnings_count;
                    }

                    domainGroups.forEach(group => {
                        group.labels = domainGroupService.getDomainGroupLabels(group, vm.allLabelsMap);
                    });
                }

                vm.domainObservable.onNext(domains);
            }, angular.noop);

            if (returnPromise !== false) {
                vm.promise = promise;
            }
        }

        function getIcon (shortcut) {
            var icon = ShortcutService.getPreSelectedIcons().find(function (icon) {
                return shortcut.icon_uri === icon.value;
            });
            if (typeof icon !== 'undefined') {
                return icon.icon;
            }
            return '';
        }

        function onFiltersChanges (changes) {
            vm.updateFilters(changes);
            vm.getPage();
        }
    }
})();
