(function () {
    'use strict';

    angular.module('app.forms').component('formDomainGroups', {
        templateUrl: 'app/forms/domain/steps/domain_groups/domain_groups.html',
        controller: DomainGroupsController,
        controllerAs: 'vm',
        bindings: {
            domain: '=',
            updateDomain: '&',
            progress: '<',
        },
    });

    DomainGroupsController.$inject = [
        'DomainGroupsRepo',
        '$controller',
        '$interval',
        '$timeout',
        'ng2MonModalService',
        'gettextCatalog',
        'domainGroupService',
        'FORM_CONSTANT',
        'MON_EVENTS',
        'ng2MonEventsService',
    ];
    /* @ngInject */
    function DomainGroupsController (
        DomainGroupsRepo,
        $controller,
        $interval,
        $timeout,
        ng2MonModalService,
        gettextCatalog,
        domainGroupService,
        FORM_CONSTANT,
        MON_EVENTS,
        ng2MonEventsService,
    ) {
        var vm = this;
        vm.$onInit = activate;
        vm.$onDestroy = onDestroy;
        vm.createGroup = createGroup;
        vm.editGroup = editGroup;
        vm.onConfirmDelete = onConfirmDelete;
        vm.getPage = getPage;
        vm.updateDomainGroupsRules = updateDomainGroupsRules;

        function activate () {
            angular.extend(vm, $controller('BaseApiController', { vm: vm }));
            vm.groups = [];
            vm.promise = null;
            vm.timerPromise = null;
            loadLocations();
            vm.progress.then(function () {
                $timeout(function () {
                    if (vm.domain.running_groups) {
                        autoReloadDomain();
                    }
                });
            });
        }

        function onDestroy () {
            if (typeof vm.timerPromise === 'object') {
                $interval.cancel(vm.timerPromise);
            }
        }

        function loadLocations () {
            getPage();
        }

        function getPage () {
            var params = {
                page: vm.page,
                page_size: vm.pageSize,
            };
            vm.promise = DomainGroupsRepo.getAll(vm.domain.id, params).then(function (data) {
                vm.groups = data;
                updateDropdowns();
            }, angular.noop);
        }

        function updateDropdowns () {
            for (const group of vm.groups) {
                const dropdown = [
                    {
                        label: gettextCatalog.getString('Edit'),
                        leftIcon: 'faCog',
                        action: ()=>!vm.domain.running_groups && editGroup(group),
                    },
                    {
                        label: gettextCatalog.getString('Remove group'),
                        leftIcon: 'faTrashAlt',
                        action: ()=>{
                            ng2MonModalService.confirm(gettextCatalog.getString('Are you sure you want to remove this group?')).then(()=>onConfirmDelete(group));
                        },
                    },
                ];

                group.dropdown = dropdown.map(item => {
                    return {
                        label: item.label,
                        leftIcon: item.leftIcon,
                        action: item.action,
                    };
                });
            }
        }

        function editGroup (group) {
            const params = {
                body: FORM_CONSTANT.FORM_DOMAIN_GROUPS_EDIT,
                size: 'lg',
                data: { domainGroup: group, domain: vm.domain },
            };

            const options = {
                params,
                callback: (content) => {
                    if (content !== undefined) {
                        getPage();
                        autoUpdate();
                    }
                },
            };

            ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, options);
        }

        function createGroup () {
            const params = {
                body: FORM_CONSTANT.FORM_DOMAIN_GROUPS_CREATE,
                size: 'lg',
                data: { domain: vm.domain },
            };

            const options = {
                params,
                callback: (content) => {
                    if (content !== undefined) {
                        getPage();
                        autoUpdate();
                    }
                },
            };

            ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, options);
        }

        function autoUpdate () {
            ng2MonModalService
                .confirm(
                    gettextCatalog.getString(
                        // eslint-disable-next-line max-len
                        'Would you like to update now? Updating groups can take more than 30 minutes. During the operation you will not be able to create and update groups',
                    ),
                )
                .then(function () {
                    updateDomainGroupsRules();
                }, angular.noop);
        }

        function updateDomainGroupsRules () {
            domainGroupService.updateDomainGroupRules(vm.domain.id).then(function () {
                vm.domain.running_groups = true;
                autoReloadDomain();
            }, angular.noop);
        }

        function autoReloadDomain () {
            vm.timerPromise = $interval(function () {
                vm.updateDomain().then(function (domain) {
                    vm.domain.running_groups = domain.running_groups;
                    if (vm.domain.running_groups === false) {
                        onDestroy();
                    }
                });
            }, 30000);
        }

        function onConfirmDelete (group) {
            domainGroupService.destroy(vm.domain.id, group.id).then(function () {
                getPage();
            }, angular.noop);
        }
    }
})();
