import { Injectable } from '@angular/core';
import { RequestHeadersType, RequestOptionsType, RequestParamsType } from '@monsido/ng2/external/http/options';
import { CancelerType } from '@monsido/ng2/external/http/http-client.service';
import { ApiClient } from '@monsido/ng2/modules/endpoints/api/api-client';
import { Observable } from 'rxjs';

/**
 * @deprecated Use `RequestHeadersType`.
 */
type ApiConfigType = {
    headers?: RequestHeadersType;
};

function isConfig (headersOrConfig?: RequestHeadersType | ApiConfigType): headersOrConfig is ApiConfigType {
    return typeof headersOrConfig === 'object' &&
        headersOrConfig !== null &&
        'headers' in headersOrConfig;
}

function toHeaders (headersOrConfig?: RequestHeadersType | ApiConfigType): RequestHeadersType {
    if (isConfig(headersOrConfig)) {
        return headersOrConfig.headers || {};
    }

    return headersOrConfig || {};
}

@Injectable({
    providedIn: 'root',
})
export class ApiService {
    constructor (
        private client: ApiClient,
    ) {
        this.client.setHeader('Accept-Version', 'v1');
    }

    setHeader (name: string, value: string): void {
        this.client.setHeader(name, value);
    }

    removeHeader (name: string): void {
        this.client.removeHeader(name);
    }

    setUrlPrefix (prefix: string): void {
        this.client.setUrlPrefix(prefix);
    }

    /**
     * @deprecated Use `getObservable`.
     *
     * @param headers Accepts `RequestHeadersType` object or a legacy "config" object
     * in the form of `{ headers: RequestHeadersType }` object.
     * The code should be refactored gradually into "plain" headers instead of nested ones.
     * After that the "config" format support should be removed.
     */
    get<T = unknown> (url: string, params?: RequestParamsType, headers?: RequestHeadersType | ApiConfigType): Promise<T> {
        return this.client.getPromise(
            url,
            {
                params,
                headers: toHeaders(headers),
            },
        );
    }

    /**
     * @deprecated Use `deleteObservable`.
     */
    delete<T = unknown> (url: string): Promise<T> {
        return this.client.deletePromise(url);
    }

    /**
     * @deprecated Use `postObservable`.
     */
    post<T = unknown> (url: string, body?: unknown, headers?: RequestHeadersType): Promise<T> {
        const options: RequestOptionsType = {
            headers,
        };
        return this.client.postPromise(url, body, options);
    }

    /**
     * @deprecated Use `patchObservable`.
     */
    patch<T = unknown> (url: string, body: unknown): Promise<T> {
        return this.client.patchPromise(url, body);
    }

    getObservable<T = unknown> (
        url: string,
        params?: RequestParamsType,
        headers?: RequestHeadersType,
        canceler$?: CancelerType,
    ): Observable<T> {
        return this.client.getObservable(url, { params, headers }, canceler$);
    }

    postObservable<T = unknown> (
        url: string,
        body?: unknown,
        canceler$?: CancelerType,
    ): Observable<T> {
        return this.client.postObservable(url, body, {}, canceler$);
    }

    patchObservable<T = unknown> (
        url: string,
        body: unknown,
        canceler$?: CancelerType,
    ): Observable<T> {
        return this.client.patchObservable(url, body, {}, canceler$);
    }

    deleteObservable<T = unknown> (
        url: string,
        canceler$?: CancelerType,
    ): Observable<T> {
        return this.client.deleteObservable(url, {}, canceler$);
    }
}
