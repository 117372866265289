import { map } from 'rxjs/operators';

(function () {
    'use strict';

    angular.module('services.api').factory('LinksRepo', LinksRepo);

    LinksRepo.$inject = ['ng2ApiService', 'ng2SessionService', 'LinkModel'];
    /* @ngInject */
    function LinksRepo (ng2ApiService, ng2SessionService, LinkModel) {
        var service = {
            getAll,
            getAllObservable,
            get,
            update,
            getOriginalUrls,
            getAllPageLinks,
        };

        return service;

        // /////////////

        function getAll (params) {
            params = params || {};
            params.group_id = getGroupId(params);
            const route = `domains/${getDomainId(params)}/links`;

            return ng2ApiService
                .get(route, params)
                .then(toModels);
        }

        function getAllObservable (params, canceler$) {
            params = params || {};
            params.group_id = getGroupId(params);
            const route = `domains/${getDomainId(params)}/links`;

            return ng2ApiService
                .getObservable(route, params, {}, canceler$)
                .pipe(map(toModels));
        }

        function get (id) {
            return ng2ApiService
                .get(`domains/${getDomainId()}/links/${id}`)
                .then(toModel);
        }

        function update (link, params) {
            params = params || {};

            if (!params.group_id) {
                params.group_id = getGroupId(params);
            }

            return ng2ApiService
                .patch(`domains/${getDomainId(params)}/links/${link.id}`, link, params)
                .then(toModel);
        }

        function getAllPageLinks (params, headers) {
            params = params || {};
            params.group_id = getGroupId(params);

            return ng2ApiService
                .get(`domains/${getDomainId(params)}/page-links`, params, headers)
                .then(toModels);
        }

        function getOriginalUrls (link, params, headers) {
            params = params || {};
            params.group_id = getGroupId(params);

            return ng2ApiService.get(`domains/${getDomainId(params)}/links/${link.id}/original-urls`, params, headers);
        }

        // PROTECTED

        function getGroupId (params) {
            return ng2SessionService.getGroupId(params);
        }

        function getDomainId (params) {
            return params.domain_id || ng2SessionService.getDomainId(params);
        }

        function toModels (models) {
            for (let i = 0; i < models.length; i++) {
                models[i] = toModel(models[i]);
            }

            return models;
        }

        function toModel (model) {
            return new LinkModel(model);
        }
    }
})();
