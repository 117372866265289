import { BrokenLinksUtil } from '@client/app/pages/qa/broken-links/broken-links.util';

(function () {
    'use strict';

    angular.module('pages.qa.broken').component('brokenImagesIndex', {
        templateUrl: 'app/pages/qa/broken-links/images/index.html',
        controller: BrokenImagesIndex,
        controllerAs: 'vm',
        bindings: {
            type: '@',
        },
    });

    BrokenImagesIndex.$inject = [
        'LinksRepo',
        'gettextCatalog',
        '$controller',
        'QABrokenLinkService',
        '$filter',
        'LabelRepo',
        'ng2MonEventsService',
    ];
    /* @ngInject */
    function BrokenImagesIndex (
        LinksRepo,
        gettextCatalog,
        $controller,
        QABrokenLinkService,
        $filter,
        LabelRepo,
        ng2MonEventsService,
    ) {
        var vm = this;
        vm.getPage = getPage;

        vm.updateFilters = updateFilters;
        vm.$onInit = activate;
        vm.$onDestroy = onDestroy;

        function activate () {
            angular.extend(vm, $controller('BaseApiController', { vm: vm }));
            vm.selected = [];
            vm.search = null;
            vm.type = vm.type || 'image';

            vm.links = [];
            vm.tabs = [
                {
                    name: gettextCatalog.getString('All broken images'),
                    faIcon: $filter('monIcons')(['LINKS', 'IMAGE']),
                    value: 'dead',
                },
                { name: gettextCatalog.getString('Ignored'), faIcon: 'fas fa-eye-slash', value: 'ignored' },
                { name: gettextCatalog.getString('Marked as fixed'), faIcon: 'fas fa-check-circle', value: 'fixed' },
            ];

            vm.isFiltersOpen = false;
            vm.filters = { labels: [], type: [], 'response code': [], 'internal or external': [] };
            vm.availableFilters = {
                labels: [],
                type: [{ id: 'html', name: 'Pages' }, { id: 'document', name: 'Documents' }],
                'response code': QABrokenLinkService.getResponseCodes(),
                'internal or external': [{ id: 'internal', name: 'Internal' }, { id: 'external', name: 'External' }],
            };

            vm.activeTab = 'dead';
            vm.currentSort = { by: 'dead_on_pages_count', direction: 'desc' };
            vm.exports = [
                {
                    name: gettextCatalog.getString('Broken images with page count'),
                    data: {
                        category: 'dead_images',
                    },
                },
                {
                    tooltip: gettextCatalog.getString(
                        'Export list of broken images organized by page and document title',
                    ),
                    name: gettextCatalog.getString('Broken Image Links per page'),
                    data: {
                        category: 'dead_page_images',
                    },
                },
            ];

            vm.qaBrokenImagesUpdateListener = ng2MonEventsService.addListener('reloadQaBrokenImages', function () {
                vm.getPage();
            });

            getLabels();
            getPage();
        }

        function getPage () {
            vm.selected = [];
            const params = {
                page: vm.page,
                page_size: vm.pageSize,
                search: vm.search,
                status: vm.activeTab,
                type: vm.type,
                sort_by: vm.currentSort.by,
                sort_dir: vm.currentSort.direction,
                page_fixed: false,
            };

            if (vm.filters.type.length) {
                params.page_type = vm.filters.type[0].id;
            }
            if (vm.selectedLabels && vm.selectedLabels.length > 0) {
                params['labels[]'] = vm.selectedLabels;
            }
            if (vm.filters['internal or external'].length) {
                params.relation = vm.filters['internal or external'][0].id;
            }
            if (vm.filters['response code'].length) {
                params.status_code = vm.filters['response code'][0].id;
            }

            vm.promise = LinksRepo.getAll(params).then(function (data) {
                vm.links = data;
            }, angular.noop);
        }

        function updateFilters (changes) {
            if (!changes) {
                return;
            }
            vm.selectedLabels = changes[gettextCatalog.getString('labels')].map(function (label) {
                return label.id;
            });
            vm.filters = BrokenLinksUtil.updateLinksFilters(vm.filters, changes);
            vm.getPage();
        }

        function getLabels () {
            LabelRepo.getLabels().then(function (data) {
                vm.labels = data || [];
                vm.availableFilters.labels = data || [];
            }, angular.noop);
        }

        function onDestroy () {
            ng2MonEventsService.remove(vm.qaBrokenImagesUpdateListener);
        }
    }
})();
